import { ColumnApi, GridApi } from 'ag-grid-community';

export const getGridConfiguration = (
    gridColumnApi?: ColumnApi,
    gridApi?: GridApi
) => {
    const columnState = gridColumnApi?.getColumnState();
    const groupColumnState = gridColumnApi?.getColumnGroupState();
    const filterModel = gridApi?.getFilterModel();
    const pivot = gridColumnApi?.isPivotMode();

    return JSON.stringify({
        pivot,
        columnState,
        groupColumnState,
        filterModel,
    });
};

export const applyGridConfiguration = (
    config: string,
    gridColumnApi?: ColumnApi,
    gridApi?: GridApi
) => {
    const columnStorageParsed = JSON.parse(config);

    const { columnState, groupColumnState, filterModel } = columnStorageParsed;
    columnState && gridColumnApi?.setColumnState(columnState);
    groupColumnState && gridColumnApi?.setColumnGroupState(groupColumnState);
    filterModel && gridApi?.setFilterModel(filterModel);
};
