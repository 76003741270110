import {
    UrlActionParams,
    extractActionParamsFromUrl,
} from './extractActionParamsFromUrl';

type TQueryAction = 'selectLocation' | 'selectReport';

export function handleQueryAction(
    action: TQueryAction,
    callback: (params: UrlActionParams) => void
): void {
    const params = extractActionParamsFromUrl();
    if (action === params?.action) {
        callback(params);
    }
}
