import React, { ReactNode, useEffect, useRef, useState } from 'react';

import { Divider, LinearProgress, Paper } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import {
    enterPreviewMode,
    resetMode,
} from '../../../../../state/ui/customerService/bottomGrid';
import {
    fetchContractItems,
    selectContractItem,
} from '../../../../../state/ui/customerService/bottomGrid/contractItems';
import {
    useSelectedGridItemId,
    useSelectedClientsAndLocations,
} from '../../../../../state/ui/customerService/clientsAndLocations/index.hooks';
import {
    useContractItems,
    useContractItemsLoading,
    useContractItemsSourceSetModel,
} from '../../../../../state/ui/customerService/bottomGrid/contractItems/index.hooks';
import { ISourceSetEntity } from '../../../../../state/types';
import {
    selectClient,
    selectLocation,
} from '../../../../../state/ui/customerService/clientsAndLocations';

import { useToolkitDispatch } from '../../../../../hooks';
import TranslationHelper from '../../../../../helpers/TranslationHelper';
import { errorMessageHandler } from '../../../../../helpers/errorMessageHandler';

import { useStyles } from '../../../Themable.hooks';

import IconButtonWithTooltip from '../../../../../components/IconButtonWithTooltip';
import PaneHeader from '../../../../../components/PaneHeader';
import SearchField from '../../../../../components/common/SearchField/SearchField';
import SourceSetGrid from '../../../../../components/SourceSetGrid';
import CscExportButtons from '../../CscExportButtons/CscExportButtons';
import { useSnackbar } from 'notistack';
interface IOwnProps {
    handleClose: () => void;
    titleComponent?: ReactNode;
}

const ContractItemsGrid = ({ handleClose, titleComponent }: IOwnProps) => {
    const notInitialRender = useRef(false);

    const sourceSetModel = useContractItemsSourceSetModel();

    const [filter, setFilter] = useState(sourceSetModel?.quickFilter || '');
    const [gridApi, setGridApi] = useState(undefined);

    const classes = useStyles();
    const contractItems = useContractItems();
    const loading = useContractItemsLoading();

    const selectedGridItemId = useSelectedGridItemId();
    const selectedClientsAndLocations = useSelectedClientsAndLocations();

    const toolkitDispatch = useToolkitDispatch();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (!loading) {
            handleFetchContractItems()
                ?.unwrap()
                .catch((error) => {
                    const message = errorMessageHandler(error.status)();
                    enqueueSnackbar(message, {
                        variant: 'error',
                    });
                });
        }
    }, [selectedClientsAndLocations]);

    useEffect(() => {
        if (notInitialRender.current) {
            toolkitDispatch(resetMode());
        } else {
            notInitialRender.current = true;
        }
    }, [selectedGridItemId]);

    const handleFetchContractItems = () => {
        if (!selectedClientsAndLocations.length) {
            return;
        }
        return toolkitDispatch(
            fetchContractItems({
                selectedClientsAndLocations,
            })
        );
    };

    const renderRightCustomControls = () => {
        return (
            <>
                <CscExportButtons api={gridApi} prefix="contracts" />
                <IconButtonWithTooltip
                    title={TranslationHelper.translate('Close')}
                    onClick={() => handleClose()}
                >
                    <CloseIcon />
                </IconButtonWithTooltip>
            </>
        );
    };

    const handleCellInteraction = (data: ISourceSetEntity) => {
        const locationId = selectedClientsAndLocations.filter(
            (item) => item.id === data.locationId?.toString()
        )[0].locationId;
        toolkitDispatch(
            selectLocation({
                id: locationId,
                externalId: data.locationId?.toString(),
            })
        );
        toolkitDispatch(selectClient(data.clientId));
        toolkitDispatch(enterPreviewMode());
        toolkitDispatch(
            selectContractItem({
                id: data.contractItemId,
                cycleId: data.cycleId,
            })
        );
    };

    const handleSetApiGrid = (
        gridApi: any,
        gridColumnApi: any | undefined = undefined
    ) => {
        setGridApi(gridApi);
    };

    return (
        <Paper className={classes.gridPane}>
            <div className={classes.wrapper}>
                <PaneHeader
                    title={TranslationHelper.translate('Contract items')}
                    titleComponent={titleComponent}
                    renderLeftCustomControls={() => (
                        <>
                            <SearchField
                                value={filter}
                                filterChange={setFilter}
                            />
                        </>
                    )}
                    renderRightCustomControls={() =>
                        renderRightCustomControls()
                    }
                />
                {!contractItems || loading ? <LinearProgress /> : <Divider />}
                {contractItems && (
                    <SourceSetGrid
                        sourceSet={contractItems}
                        cellInteractionHandler={handleCellInteraction}
                        setGridApi={handleSetApiGrid}
                        quickFilterValue={filter}
                        sourceSetModel={sourceSetModel}
                    />
                )}
            </div>
        </Paper>
    );
};

export default ContractItemsGrid;
