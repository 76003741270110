import React, { ReactNode, useEffect, useRef, useState } from 'react';

import { useSnackbar } from 'notistack';

import { Divider, LinearProgress, Paper } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import {
    enterPreviewMode,
    resetMode,
} from '../../../../../state/ui/customerService/bottomGrid';

import {
    useSelectedGridItemId,
    useSelectedClientsAndLocations,
} from '../../../../../state/ui/customerService/clientsAndLocations/index.hooks';

import { useAppDispatch, useToolkitDispatch } from '../../../../../hooks';
import TranslationHelper from '../../../../../helpers/TranslationHelper';
import { errorMessageHandler } from '../../../../../helpers/errorMessageHandler';

import { useStyles } from '../../../Themable.hooks';

import IconButtonWithTooltip from '../../../../../components/IconButtonWithTooltip';
import PaneHeader from '../../../../../components/PaneHeader';
import SearchField from '../../../../../components/common/SearchField/SearchField';
import SourceSetGrid from '../../../../../components/SourceSetGrid';
import { ISourceSetEntity } from '../../../../../state/types';
import DateRangeDisplay from '../../../../../components/DateRangeDisplay';
import CscExportButtons from '../../CscExportButtons/CscExportButtons';
import {
    fetchEvents,
    selectEvent,
    setDates,
} from '../../../../../state/ui/customerService/bottomGrid/events';
import {
    useEventDates,
    useEventId,
    useEvents,
    useEventsStatus,
    useEventsSourceSetModel,
} from '../../../../../state/ui/customerService/bottomGrid/events/index.hooks';
import { activatePreview } from '../../../../../state/_actions';
import preparePreviewAction from '../../../../../state/_actions/preparePreviewAction';

interface IOwnProps {
    handleClose: () => void;
    titleComponent?: ReactNode;
}

const EventsGrid = ({ handleClose, titleComponent }: IOwnProps) => {
    const toolkitDispatch = useToolkitDispatch();
    const dispatch = useAppDispatch();

    const notInitialRender = useRef(false);

    const sourceSetModel = useEventsSourceSetModel();

    const [filter, setFilter] = useState(sourceSetModel?.quickFilter || '');
    const [gridApi, setGridApi] = useState(undefined);

    const dates = useEventDates();
    const classes = useStyles();
    const events = useEvents();
    const status = useEventsStatus();
    const eventId = useEventId();

    const selectedGridItemId = useSelectedGridItemId();
    const selectedClientsAndLocations = useSelectedClientsAndLocations();

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (status !== 'loading') {
            handleFetchEvents(dates.from, dates.to)
                ?.unwrap()
                .catch((error) => {
                    const message = errorMessageHandler(error.status)();
                    enqueueSnackbar(message, {
                        variant: 'error',
                    });
                });
        }
    }, [selectedClientsAndLocations]);

    useEffect(() => {
        if (notInitialRender.current) {
            toolkitDispatch(resetMode());
        } else {
            notInitialRender.current = true;
        }
    }, [selectedGridItemId]);

    const handleFetchEvents = (from: string, to: string) => {
        if (!selectedClientsAndLocations.length) {
            return;
        }
        toolkitDispatch(setDates({ from, to }));

        const locationsWithCoordinates = selectedClientsAndLocations.filter(
            (location) => location.coordinates
        );
        return toolkitDispatch(
            fetchEvents({
                selectedClientsAndLocations: locationsWithCoordinates,
                from,
                to,
            })
        );
    };

    const renderRightCustomControls = () => {
        return (
            <>
                <CscExportButtons api={gridApi} prefix="events" />
                <IconButtonWithTooltip
                    title={TranslationHelper.translate('Close')}
                    onClick={() => handleClose()}
                >
                    <CloseIcon />
                </IconButtonWithTooltip>
            </>
        );
    };

    const handleCellInteraction = (data: ISourceSetEntity) => {
        toolkitDispatch(enterPreviewMode());
        toolkitDispatch(selectEvent(data.id));
        dispatch(
            activatePreview(
                'archiveEvents',
                data.id,
                'sourceSetElement',
                'preview',
                {
                    type: 'source-set-element',
                    level: 0,
                },
                preparePreviewAction(
                    undefined,
                    data,
                    {
                        api: '/rest/api/source-sets/archive-events/{id}',
                        label: 'Show',
                        method: 'GET',
                        componentName: undefined,
                        params: {},
                        _webx: {
                            changeSourceSetDisabled: true,
                        },
                    },
                    data._meta.icon,
                    { id: data.id }
                ),
                false,
                undefined,
                false
            )
        );
    };
    const handleSetApiGrid = (
        gridApi: any,
        gridColumnApi: any | undefined = undefined
    ) => {
        setGridApi(gridApi);
    };
    return (
        <Paper className={classes.gridPane}>
            <div className={classes.wrapper}>
                <PaneHeader
                    title={TranslationHelper.translate('Events')}
                    titleComponent={titleComponent}
                    renderLeftCustomControls={() => (
                        <>
                            <SearchField
                                value={filter}
                                filterChange={setFilter}
                            />
                            <DateRangeDisplay
                                onDateChange={handleFetchEvents}
                                sourceSetId={events?.id}
                                dates={dates}
                                datesFormat={'short'}
                                small
                            />
                        </>
                    )}
                    renderRightCustomControls={() =>
                        renderRightCustomControls()
                    }
                />
                {status === 'loading' ? <LinearProgress /> : <Divider />}
                {status === 'done' && events && (
                    <SourceSetGrid
                        sourceSet={events}
                        cellInteractionHandler={handleCellInteraction}
                        quickFilterValue={filter}
                        setGridApi={handleSetApiGrid}
                        sourceSetModel={sourceSetModel}
                        selectedSourceSetElementId={String(eventId)}
                    />
                )}
                {status === 'done' && !events && (
                    <div className={classes.noDataContainer}>
                        {TranslationHelper.translate(
                            'None of selected locations have coordinates set'
                        )}
                    </div>
                )}
            </div>
        </Paper>
    );
};

export default EventsGrid;
