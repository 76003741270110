import React, { FormEvent, useState } from 'react';

import { useMediaQuery } from '@material-ui/core';

import { useStyles } from '../Themable.hooks';

import { storeAuthData } from '../../../state/auth';
import { loginUser } from '../../../state/login';
import { useAds, useCustomers } from '../../../state/login/index.hooks';

import { MOBILE } from '../../../constants/dictionaries/ScreenSizeConst';

import { useAppDispatch } from '../../../hooks';
import useStateReducer from '../../../hooks/useStateReducer';

import { LoginPageForm } from './LoginPageForm';
import FullScreenSpinner from '../../../components/FullScreenSpinner/FullScreenSpinner';
import AdsCarousel from '../../../components/AdsCarousel/AdsCarousel';
import { LoginPageBackGround } from './LoginPageBackGround';
import { TLoginRequiredActions } from 'src/app/state/login/types';

interface IOwnProps {
    redirectAfterLogin: () => void;
    handleActions: (actions: TLoginRequiredActions[]) => void;
}
const LoginPage = ({ redirectAfterLogin, handleActions }: IOwnProps) => {
    const errorsInitialState = {
        loginError: false,
        emptyLoginError: false,
        emptyPasswordError: false,
    };

    const [loggingIn, setLoggingIn] = useState(false);
    const classes = useStyles();
    const isMobile = useMediaQuery(MOBILE);

    const customers = useCustomers();
    const ads = useAds();

    const { values: errors, handleChangeValues } =
        useStateReducer(errorsInitialState);

    const dispatch = useAppDispatch();

    const handleLoginUser = (
        event: FormEvent,
        data: {
            login: string;
            password: string;
            customer: string;
            language: string;
        }
    ) => {
        event.preventDefault();
        const { login, password, customer, language } = data;

        const emptyLoginError = login.length === 0;
        const emptyPasswordError = password.length === 0;

        if (emptyLoginError) {
            handleChangeValues('emptyLoginError', true);
            handleChangeValues('loginError', false);
        }
        if (emptyPasswordError) {
            handleChangeValues('emptyPasswordError', true);
            handleChangeValues('loginError', false);
        }
        if (!emptyLoginError && !emptyPasswordError) {
            handleChangeValues('emptyLoginError', false);
            handleChangeValues('emptyPasswordError', false);

            setLoggingIn(true);
            dispatch(
                loginUser(
                    {
                        login,
                        password,
                        customer,
                        language,
                        extended: true,
                    },
                    (data) => {
                        dispatch(storeAuthData(data));
                        setTimeout(() => {
                            handleChangeValues('loginError', false);

                            if (data.requiredActions.length) {
                                handleActions(data.requiredActions);
                            } else {
                                redirectAfterLogin();
                            }
                        }, 100);
                    },
                    (error) => {
                        setLoggingIn(false);
                        handleChangeValues('loginError', true);
                    }
                )
            );
        }
    };

    const renderSpinner = () => {
        return loggingIn ? <FullScreenSpinner /> : null;
    };

    return (
        <LoginPageBackGround>
            <>
                <div className={classes.loginComponents}>
                    <div className={classes.columnLeft}>
                        <LoginPageForm
                            handleLoginUser={handleLoginUser}
                            errors={errors}
                        />
                    </div>
                    {!!ads?.length && !isMobile && (
                        <AdsCarousel
                            ads={ads}
                            size={customers && customers.length > 1 ? 517 : 455}
                        />
                    )}
                </div>
                {renderSpinner()}
            </>
        </LoginPageBackGround>
    );
};

export default LoginPage;
