interface IError {
    message: string;
    responseJSON?: string;
}

export default {
    send(settings: any): Promise<any> {
        return fetch(settings.url, {
            method: settings.method || 'GET',
            headers: settings.headers,
            body: prepareBody(settings),
        })
            .then((response) => {
                if (response.status === 401) {
                    const expire = 'expires=Thu, 01 Jan 1970 00:00:00 UTC';
                    document.cookie = 'login=; ' + expire;
                    document.cookie = 'customer=; ' + expire;
                    document.cookie = 'XTrack-Web-Token=; ' + expire;
                    throw { status: response.status };
                } else if (!response.ok) {
                    return response.text().then((text) => {
                        let error: IError = { message: text };
                        try {
                            error.responseJSON = JSON.parse(text);
                        } catch (e) {
                            // If response body is not valid JSON, ignore parsing error
                        }
                        throw {
                            status: response.status,
                            responseJSON: error.responseJSON,
                        };
                    });
                }
                return prepareResponse(settings, response);
            })
            .catch((error) => {
                console.error('Fetch error:', error);
                throw error;
            });
    },
    getJsonConfiguration(fileName: string): Promise<any> {
        return fetch('/configuration/' + fileName)
            .then((response) => response.json())
            .catch((error) => {
                console.error('Fetch error:', error);
                throw error;
            });
    },
    sendWithoutErrorsHandling(settings: any): Promise<any> {
        return fetch(settings.url, {
            method: settings.method || 'GET',
            headers: settings.headers,
            body: prepareBody(settings),
        })
            .then((response) => {
                if (response.status === 401) {
                    const expire = 'expires=Thu, 01 Jan 1970 00:00:00 UTC';
                    document.cookie = 'login=; ' + expire;
                    document.cookie = 'customer=; ' + expire;
                    document.cookie = 'XTrack-Web-Token=; ' + expire;
                    throw { status: response.status };
                }
                return prepareResponse(settings, response);
            })
            .catch((error) => {
                console.error('Fetch error:', error);
                throw error;
            });
    },
    async sendWithRetry(settings: any) {
        const response = await fetch(settings.url, {
            method: settings.method || 'GET',
            headers: settings.headers,
            body: prepareBody(settings),
        });
        if (response.status === 202) {
            const result = await retryFetch(settings);
            return prepareResponse(settings, result);
        } else {
            return prepareResponse(settings, response);
        }
    },
};

const retryFetch = async (
    settings: any,
    retriesLeft = 3
): Promise<Response> => {
    if (retriesLeft === 0) {
        throw new Error('Maximum number of retries reached');
    }

    await new Promise((resolve) => setTimeout(resolve, 1000)); // Adjust delay as needed

    const response = await fetch(settings.url, {
        method: settings.method || 'GET',
        headers: settings.headers,
        body: prepareBody(settings),
    });

    if (response.status === 202) {
        return retryFetch(settings, retriesLeft - 1);
    } else {
        return response;
    }
};

const prepareResponse = (settings: any, response: Response) => {
    if (settings.method === 'DELETE') {
        return response;
    }
    const contentType = response.headers.get('content-type');
    if (contentType) {
        return response.json();
    }
    return response;
};

const prepareBody = (settings: any) => {
    if (!settings || !settings.method || settings.method === 'GET') {
        return undefined;
    }
    if (
        settings.contentType ===
        'application/x-www-form-urlencoded; charset=UTF-8'
    ) {
        return new URLSearchParams(settings.data);
    }
    if (settings.contentType === 'application/json') {
        return settings.data;
    }
    return settings.data;
};
