import { makeStyles } from '@material-ui/core/styles';

import '../../../assets/images/about-wiss.png';
import '../../../assets/images/kingspan-logo-full.svg';
import '../../../assets/images/login_bg.png';
import '../../../assets/images/logo-borim.png';
import '../../../assets/images/logo-hiab-small.png';
import '../../../assets/images/logo-hidrive-big.png';

export const useStyles = makeStyles((theme) => {
    return {
        root: {
            height: '100vh',
            width: '100vw',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        defaultThemeBg: {
            backgroundImage: `url('/images/login_bg.png')`,
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            height: '100vh',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        formField: {
            margin: '10px 0',
        },
        formFieldLabel: {
            display: 'block',
            marginBottom: '2px',
        },
        loginLogo: {
            display: 'block',
            width: '100%',
            height: '150px',
            margin: '0px auto 30px auto',
            padding: 0,

            [theme.breakpoints.down('xs')]: {
                width: '100%',
                margin: 0,
            },
        },
        borim: {
            width: '100%',
            height: '65px',
            backgroundColor: '#1f1f1f',
            margin: '10px auto 30px auto',
            paddingBottom: 30,
            backgroundImage: `url('/images/logo-borim.png')`,
            backgroundSize: '358px 65px',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        },
        wiss: {
            width: '100%',
            height: '126px',
            margin: '10px auto 30px auto',
            backgroundImage: `url('/images/about-wiss.png')`,
            backgroundSize: '378px 126px',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        },
        kingspan: {
            width: '100%',
            height: '126px',
            margin: '10px auto 30px auto',
            backgroundImage: `url('/images/kingspan-logo-full.svg')`,
            backgroundSize: '378px 126px',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        },
        hiab: {
            width: '100%',
            height: '113px',
            margin: '0px auto 30px auto',
            backgroundImage: `url('/images/logo-hidrive-big.png')`,
            backgroundSize: '227px 113px',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        },
        hiabAdditional: {
            width: '96px',
            height: '32px',
            position: 'relative',
            zIndex: 100,
            marginTop: 10,
            backgroundImage: `url('/images/logo-hiab-small.png')`,
            backgroundPosition: 'left center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '96px 32px',
        },
        loginForm: {
            maxWidth: '378px',
            padding: '0px 20px',
            border: '1px solid #D1D1D1',
            borderRadius: '3px',
            backgroundColor: 'white',

            [theme.breakpoints.down('xs')]: {
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                margin: 0,
                maxWidth: 'none',
            },
        },
        search: {
            fontSize: '12px',
            width: '160px',

            [theme.breakpoints.down('xs')]: {
                width: '100%',
            },
        },
        formButtons: {
            display: 'flex',
            margin: '10px 0',
            alignItems: 'center',
            justifyContent: 'space-between',

            [theme.breakpoints.down('xs')]: {
                display: 'flex',
                flexDirection: 'column-reverse',
                alignItems: 'flex-start',
            },
        },
        errorText: {
            fontSize: theme.typography.caption.fontSize,
            color: theme.palette.error.main,
        },
        mainColor: {
            background: theme.palette.primary.main,
        },
        passwordResetLink: {
            fontSize: 12,

            [theme.breakpoints.down('xs')]: {
                marginTop: 5,
            },
        },
        mobileLogo: {
            width: '100%',
        },
        loginComponents: {
            display: 'flex',
        },
        columnLeft: {
            flex: '1',
            width: '400px',
        },
    };
});
