export interface IColor {
    color: string;
    hidden: boolean;
    rgb: { r: number; g: number; b: number };
}
export const COLORS = [
    { color: '#e53935', hidden: false, rgb: { r: 229, g: 57, b: 53 } },
    { color: '#3949AB', hidden: false, rgb: { r: 57, g: 73, b: 171 } },
    { color: '#7CB342', hidden: false, rgb: { r: 124, g: 179, b: 66 } },
    { color: '#FDD835', hidden: false, rgb: { r: 253, g: 216, b: 53 } },
    { color: '#FB8C00', hidden: false, rgb: { r: 251, g: 140, b: 0 } },
    { color: '#8E24AA', hidden: false, rgb: { r: 142, g: 36, b: 170 } },
    { color: '#039BE5', hidden: false, rgb: { r: 3, g: 155, b: 229 } },
    { color: '#6D4C41', hidden: false, rgb: { r: 109, g: 76, b: 65 } },
    { color: '#00897B', hidden: false, rgb: { r: 0, g: 137, b: 123 } },
    { color: '#546E7A', hidden: false, rgb: { r: 84, g: 110, b: 122 } },
];

export const DEFAULT_COLOR = {
    color: '#808080',
    hidden: false,
    rgb: { r: 128, g: 128, b: 128 },
};
