import React, { FocusEvent } from 'react';
import { compose } from 'redux';
import { LinearProgress } from '@material-ui/core';

import { Field, FormikErrors, FormikValues } from 'formik';

import { Connectable, TConnectableProps } from './Connectable.hoc';

import FieldWrapper from '../../../../components/common/formikWrappers/FieldWrapper';
import TranslationHelper from '../../../../helpers/TranslationHelper';
import { IParamAlertCondition } from '../../../../state/ui/forms';
import {
    convertParamType,
    mapDictionaryItems,
    paramTypes,
} from '../../_utils/utils';
import { useStyles } from '../FormsThemable.hooks';
import { IDialogProp } from '../../AlertSettingDialog';

interface IOwnProps {
    errors?: FormikErrors<IParamAlertCondition>;
    setFieldValue: (name: string, value: any) => void;
    values: FormikValues;
    name: string;
    dialog: IDialogProp;
}

type TProps = IOwnProps & TConnectableProps;

const ParameterAlertDefinitionForm = ({
    name,
    definitions,
    paramsAvailable,
    fetchAlertDefinitions,
    setChosenParams,
    errors,
    setFieldValue,
    values,
    dialog,
    chosenParams,
}: TProps) => {
    const classes = useStyles({});
    const { mode } = dialog;
    const inEditMode = mode === 'edit';
    const chosenParamType = chosenParams[values.paramName]?.type;
    let definitionsForParam = chosenParamType
        ? definitions[convertParamType(chosenParamType)]
        : undefined;

    //errors
    const valueError = errors?.paramValue;
    const selectedParamHelperText = errors && errors?.paramName;
    const definitionId = errors && errors?.definitionId;

    const afterParamSelect = (value: string) => {
        const selectedParam = paramsAvailable.find(
            (param) => param.name === value
        );

        const areDefinitionsForTypePresent =
            (selectedParam && definitions[selectedParam.type]) || undefined;
        const areLastTypesDifferent =
            selectedParam?.type !== values.chosenParam?.type;
        if (selectedParam) {
            setChosenParams(selectedParam);
        }
        if (areLastTypesDifferent) {
            setFieldValue(`${name}.definitionId`, '');
        }
        if (selectedParam && !areDefinitionsForTypePresent) {
            fetchAlertDefinitions(convertParamType(selectedParam.type));
        }
    };

    const trimValue = (event: FocusEvent<HTMLInputElement>) => {
        const value = event.target.value.trim();
        setFieldValue(event.target.name, value);
    };

    const getValueField = (type: string = '') => {
        const commonProps = {
            className: classes.field,
            label: TranslationHelper.translate('Trigger value'),
            fullWidth: true,
            component: FieldWrapper,
            required: true,
            variant: 'outlined',
        };

        switch (type) {
            case paramTypes.STRING:
                return (
                    <Field
                        error={!!valueError}
                        helperText={valueError}
                        name={`${name}.paramValue`}
                        onBlur={trimValue}
                        {...commonProps}
                    />
                );
            case paramTypes.FLOAT:
            case paramTypes.INTEGER:
            case paramTypes.INTEGER_64:
                return (
                    <Field
                        error={!!valueError}
                        helperText={valueError}
                        name={`${name}.paramValue`}
                        type={'number'}
                        inputProps={{
                            min: '-9999999',
                            max: '9999999',
                        }}
                        {...commonProps}
                    />
                );
            default:
                return <Field disabled={true} value={''} {...commonProps} />;
        }
    };
    return (
        <>
            <Field
                className={classes.fieldLg}
                error={!!selectedParamHelperText}
                disabled={inEditMode}
                helperText={selectedParamHelperText}
                name={`${name}.paramName`}
                label={TranslationHelper.translate(
                    'Trigger alert on parameter'
                )}
                select={true}
                fullWidth={true}
                component={FieldWrapper}
                required={true}
                afterOnChange={afterParamSelect}
                variant="outlined"
            >
                {mapDictionaryItems(paramsAvailable, undefined, 'name', 'mp')}
            </Field>
            <Field
                className={classes.fieldMd}
                error={!!definitionId}
                helperText={definitionId}
                disabled={inEditMode || !definitionsForParam}
                name={`${name}.definitionId`}
                label={TranslationHelper.translate('Alert definition')}
                select={true}
                fullWidth={true}
                component={FieldWrapper}
                required={true}
                variant="outlined"
            >
                {mapDictionaryItems(definitionsForParam, false, 'id', 'wx')}
            </Field>
            {!definitions && <LinearProgress />}

            {getValueField(chosenParams[values.paramName]?.type)}
        </>
    );
};

export default compose(Connectable)(ParameterAlertDefinitionForm);
