import React, { useState } from 'react';

import { Paper } from '@material-ui/core';
import { Box } from '@mui/material';

import VectorSource from 'ol/source/Vector';
import { Geometry } from 'ol/geom';

import { useIsRoutesPaneVisible } from '../../state/ui/planning/routes/index.hooks';

import TranslationHelper from '../../helpers/TranslationHelper';

import { useStyles } from './Themable.hooks';

import EditOverlay from '../discovery/components/EditOverlay';

import View from '../../components/View';
import PageContent from '../../components/PageContent';
import PlanningRoutesMap from './components/PlanningRoutesMap/PlanningRoutesMap';
import RoutesGrid from './components/PlanningRoutes/RoutesGrid';
import PlanningTasksGrid from './components/PlanningTasks/PlanningTasksGrid';
import RoutesPane from './components/PlanningRoutes/RoutesPane/RoutesPane';
import { FEATURE_SELECTED } from './components/PlanningRoutesMap/utils';

const PlanningPage = () => {
    const routesPaneVisible = useIsRoutesPaneVisible();
    const classes = useStyles();
    const bottomGrid = true;
    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    const [source, setSource] = useState<VectorSource<Geometry> | null>(null);

    const handleSelectFeatures = (items: string[]) => {
        setSelectedItems(items);
    };

    const handleFeatureSelectedOnGrid = (items: string[]) => {
        if (!source) return;

        const itemSet = new Set(items);
        const features = source.getFeatures();
        setSelectedItems(items);
        features.forEach((feature) => {
            const data = feature?.get('data');
            const id = data?.id;

            // Unset 'selected' for all features first
            feature.unset('selected');

            // Set 'selected' if the feature's id is in the itemSet
            if (id && itemSet.has(id)) {
                feature.set('selected', FEATURE_SELECTED);
            }
        });
    };
    const gridPaneClass = bottomGrid
        ? classes.visibleBottomGrid
        : classes.hiddenBottomGrid;
    const routesGridClass = routesPaneVisible
        ? classes.visibleGridPane
        : classes.hiddenGridPane;

    return (
        <View title={TranslationHelper.translate('Routes planning')}>
            <PageContent>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        height: '100%',
                    }}
                >
                    <div>
                        <div className={gridPaneClass}>
                            <div className={routesGridClass}>
                                <RoutesGrid />
                            </div>
                        </div>
                        {bottomGrid && (
                            <div className={classes.bottomGrid}>
                                <div className={routesGridClass}>
                                    <PlanningTasksGrid
                                        selectedItems={selectedItems}
                                        handleSelectFeatures={
                                            handleSelectFeatures
                                        }
                                        handleFeatureSelectedOnGrid={
                                            handleFeatureSelectedOnGrid
                                        }
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    {routesPaneVisible && (
                        <Paper className={classes.paneWrapper}>
                            <RoutesPane />
                        </Paper>
                    )}
                    <PlanningRoutesMap
                        selectItems={handleSelectFeatures}
                        selectedItems={selectedItems}
                        source={source}
                        setSource={setSource}
                    />
                </Box>
            </PageContent>
            <EditOverlay />
        </View>
    );
};

export default PlanningPage;
