import { combineReducers } from 'redux';

import admin from './admin';
import customerService from './customerService';
import dashboard from './dashboard';
import devices from './devices';
import discovery from './discovery';
import forms from './forms';
import general from './general';
import globalDialog from './dialog';
import groupsSlice from './groupsSlice';
import management from './management';
import reports from './reportsSlice';
import roles from './roles';
import planning from './planning';
import resources from './resources';
import usersSlice from './usersSlice';
import userSettings from './userSettings';
import settings from './settings';
import videoUrl from './videoUrl';
import gridViews from './gridViews';
export default combineReducers({
    admin,
    customerService,
    general,
    devices,
    dashboard,
    discovery,
    forms,
    globalDialog,
    groupsSlice,
    management,
    reports,
    planning,
    resources,
    roles,
    usersSlice,
    userSettings,
    settings,
    videoUrl,
    gridViews,
});
