import { useSnackbar } from 'notistack';
import React from 'react';

import {
    createSchedule,
    INewSchedule,
} from '../../../../../../state/ui/reportsSlice';

import { errorMessageHandler } from '../../../../../../helpers/errorMessageHandler';
import TranslationHelper from '../../../../../../helpers/TranslationHelper';

import { useToolkitDispatch } from '../../../../../../hooks';

import SchedulesForm from '../SchedulesForm';
import { IInitialState } from '../SchedulesForm/SchedulesForm';
import moment from 'moment';
import { useUserTimeZone } from '../../../../../../state/user/index.hooks';
import { useTimeZones } from '../../../../../../state/app/dictionaries/index.hooks';

interface IOwnProps {
    handleClose: () => void;
}

const SchedulesCreateForm = ({ handleClose }: IOwnProps) => {
    const timeZones = useTimeZones();

    const toolkitDispatch = useToolkitDispatch();

    const userTimeZone = useUserTimeZone();

    const getTimeZone = () => {
        return timeZones.filter((timeZone) => {
            return timeZone.shortName === userTimeZone;
        })?.[0];
    };
    const { enqueueSnackbar } = useSnackbar();

    const initialState: IInitialState = {
        reportType: { typeId: '', name: '', templateId: '' },
        periodicity: '',
        timeZone: getTimeZone() || { id: -1, name: '' },
        dateTo: moment().add(1, 'day').startOf('day'),
        dateFrom: moment().startOf('day'),
        objects: [],
        emails: [],
        emailsField: '',
    };

    const handleRequest = (data: INewSchedule, callback: () => void) =>
        toolkitDispatch(createSchedule(data))
            .unwrap()
            .then(() => {
                enqueueSnackbar(
                    TranslationHelper.translate('Schedule created'),
                    {
                        variant: 'success',
                    }
                );

                callback();
            })
            .catch((error) => {
                const message = errorMessageHandler(error.status)();
                enqueueSnackbar(message, { variant: 'error' });
                callback();
            });

    return (
        <SchedulesForm
            title="Create schedule"
            initialState={initialState}
            handleClose={handleClose}
            handleRequest={handleRequest}
        />
    );
};

export default SchedulesCreateForm;
