import { useSelector } from 'react-redux';
import {
    getRoutesPaneMode,
    getRoutesAsSouceSet,
    getRoutesSourceSetModel,
    getRoutesStatus,
    getSelectedRoute,
    getSelectedRouteId,
    getSelectedRoutes,
    isRoutesPaneVisible,
    getSelectedCategory,
    getSelectedDates,
} from './';

export const useIsRoutesPaneVisible = () => useSelector(isRoutesPaneVisible);
export const useRoutes = () => useSelector(getRoutesAsSouceSet);
export const useRoutesMode = () => useSelector(getRoutesPaneMode);
export const useRoutesSourceSetModel = () =>
    useSelector(getRoutesSourceSetModel);
export const useRoutesStatus = () => useSelector(getRoutesStatus);
export const useSelectedRoute = () => useSelector(getSelectedRoute);
export const useSelectedRouteId = () => useSelector(getSelectedRouteId);

export const useSelectedRoutes = () => useSelector(getSelectedRoutes);
export const useSelectedCategory = () => useSelector(getSelectedCategory);
export const useSelectedDates = () => useSelector(getSelectedDates);
