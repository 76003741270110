import { createStyles, WithStyles } from '@material-ui/core';
import { Theme, withStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
    return createStyles({
        contrastText: {
            color: theme.palette.getContrastText(theme.palette.primary.main),
        },
        wrapper: {
            display: 'flex',
            position: 'relative',
            flexGrow: 1,
            flexDirection: 'column',
            height: 500,
            width: 500,
        },
        gridBorder: {
            border: `1px solid #BDC3C7`,
            display: 'flex',
            flexGrow: 1,
        },
        progress: {
            marginTop: -theme.spacing(),
        },
    });
};

export type TThemableProps = WithStyles<typeof styles>;

export const Themable = withStyles(styles, { withTheme: true });
