import { ICellRendererParams } from 'ag-grid-community';

import TranslationHelper from '../../../helpers/TranslationHelper';
import { IReportEntity } from '../../../state/types';
import { IMultiIcon } from './types';

const generateMaterialIcon = (params: IMultiIcon[] | string) => {
    if (typeof params !== 'string') {
        return params
            .map(
                (icon) =>
                    `<i title="${TranslationHelper.translate(
                        icon.label
                    )}" class="material-icons color-grey">${icon.name}</i>`
            )
            .join('');
    } else {
        return `<i class="material-icons color-grey">${params}</i>`;
    }
};

const generateMaterialStatusIcon = (value: boolean) => {
    switch (value) {
        case true:
            return `<i class="material-icons" style="color: green">check</i>`;
        case false:
            return `<i class="material-icons" style="color: red">close</i>`;
        default:
            return `<span></span>`;
    }
};

type TAlertIcons = 'read' | 'unread';

const generateMaterialIconForAlerts = (value: TAlertIcons) => {
    switch (value) {
        case 'read':
            return `<i class="material-icons" style="color: green">notifications</i>`;
        case 'unread':
            return `<i class="material-icons" style="color: red">notifications_active</i>`;
    }
};

export const cellRenderers = {
    tooltipList: 'groupsCellRenderer',
    actions: 'actionsCellRenderer',
    bar: 'agSparklineCellRenderer',
    icon: (params: IReportEntity) =>
        params.value
            ? `<span>${generateMaterialIcon(params.value as string)}</span>`
            : '<span></span>',
    statusIcon: (params: ICellRendererParams) =>
        `<span>${generateMaterialStatusIcon(params.value)}</span>`,
    iconAttachment: (params: IReportEntity) =>
        params.value
            ? `<span>${generateMaterialIcon('attach_file' as string)}</span>`
            : '<span></span>',
    alertIcon: (params: ICellRendererParams) =>
        params.value
            ? `<span>${generateMaterialIconForAlerts(
                  params.value as TAlertIcons
              )}</span>`
            : '<span></span>',
    multiIcon: (params: any) =>
        params.value
            ? `<span>${generateMaterialIcon(params.value)}</span>`
            : '<span></span>',
};
