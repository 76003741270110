import { useSelector } from 'react-redux';
import { TRootState } from '../../../store';

import {
    getClustering,
    getCurrentSnapshot,
    getDiscoveryMapSourceSet,
    getGeneralClustering,
    getGridCreatorLevel,
    getMapFitToExtentIsPending,
    getPreviewPaneObjectId,
    getReportSet,
    getSelectedMonitoredObject,
    getTimeLock,
    getTrail,
    isDiscoverUIGridFullscreen,
    shouldMapBeInEditMode,
    mapInDrawMode,
    areSearcherResultsBeingFetched,
    getDiscoveryUIPreviewPane,
    getLocationsRegister,
    getPreviewCreatorLevel,
    getPreviewTask,
    getSearcherResults,
    isLocationsRegisterBeingFetched,
    getPrivileges,
    areSearcherResultsVisible,
    getIncludeNotPlanned,
    getNotAssignedFilter,
    getSourceSetDates,
    isAnalyticsModeEnabled,
    isDiscoveryUIChartPaneVisible,
    isDiscoverUIDataGridPaneVisible,
    isDiscoveryUICalibrationPaneVisible,
    isDiscoveryUIDetectionPaneVisible,
    getModeledGridSourceSet,
    getGridChartFlag,
    getDataGridObjectName,
    getSourceSetModel,
    getDiscoveryUIDataGridPane,
    getPreviewReportSet,
    getSourceSetTime,
    getSourceSetMovieFramesIds,
    getFramesMoviesIds,
    getPreviewAction,
    getPreviewSourceSetElement,
    getActiveChartAttributes,
    getPreviewLocation,
    shouldDiscoveryBeInEditMode,
    getGridFilters,
    getNearEventsRadius,
    isSourceSetElementFetching,
    getLastMapClickPosition,
    getPreviewLastState,
    getGridSourceSetEntitiesIds,
    isLastStateBeingFetched,
    getGridSourceSetId,
    isSourceSetBeingFetched,
    isTrailBeingFetched,
    getActionsToTrigger,
    isReportSetBeingFetched,
    getDiscoveryUIDialog,
    getExtendedReports,
    getPreviewElementId,
    getPreviewDataSegmentsReports,
    getDataSegmentsTrails,
    getTaskFetching,
    getSourceSetElementStartDate,
    getSourceSetElementMonitoredId,
    getFrameElementMonitoredId,
    getFrameElementDate,
    getSourceSetElementDate,
} from './index';

export const useSnapshot = () => useSelector(getCurrentSnapshot);
export const useSnapshotClustering = () => useSelector(getClustering);
export const useSnapshotActionsToTrigger = () =>
    useSelector(getActionsToTrigger);
export const useGridSnapshot = () => useSelector(getDiscoveryUIDataGridPane);
export const usePreviewDataSegmentsReports = () =>
    useSelector(getPreviewDataSegmentsReports);
export const usePreviewReportSet = () => useSelector(getPreviewReportSet);
export const usePreviewPaneId = () => useSelector(getPreviewPaneObjectId);
export const useTask = () => useSelector(getPreviewTask);
export const useTaskFetching = () => useSelector(getTaskFetching);
export const useCreatorLevel = () => useSelector(getPreviewCreatorLevel);
export const usePreviewAction = () => useSelector(getPreviewAction);
export const usePreviewSourceSetElement = () =>
    useSelector(getPreviewSourceSetElement);

export const useReportSet = () => useSelector(getReportSet);
export const useExtendedReports = () => useSelector(getExtendedReports);

export const usePane = () => useSelector(getDiscoveryUIPreviewPane);
export const usePreviewElementId = () => useSelector(getPreviewElementId);

export const useActiveChartAttributes = () =>
    useSelector(getActiveChartAttributes);
export const useIsChartPaneVisible = () =>
    useSelector(isDiscoveryUIChartPaneVisible);
export const useIsDataGridPaneVisible = () =>
    useSelector(isDiscoverUIDataGridPaneVisible);
export const useIsCalibrationDataChartPaneVisible = () =>
    useSelector(isDiscoveryUICalibrationPaneVisible);
export const useIsDetectionChartPaneVisible = () =>
    useSelector(isDiscoveryUIDetectionPaneVisible);
export const useAreEmployeesSearchResultsVisible = () =>
    useSelector((state: TRootState) =>
        areSearcherResultsVisible(state, 'employees')
    );
export const useAreVehiclesSearchResultsVisible = () =>
    useSelector((state: TRootState) =>
        areSearcherResultsVisible(state, 'vehicles')
    );
export const useEmployeesSearcherResults = () =>
    useSelector((state: TRootState) => getSearcherResults(state, 'employees'));
export const useVehiclesSearcherResults = () =>
    useSelector((state: TRootState) => getSearcherResults(state, 'vehicles'));

export const useIsGridChart = () => useSelector(getGridChartFlag);

export const useLocationsRegister = () => useSelector(getLocationsRegister);

export const useIsLocationsFetching = () =>
    useSelector(isLocationsRegisterBeingFetched);
export const useIsSourceSetElementFetching = () =>
    useSelector(isSourceSetElementFetching);
export const useIsLastStateBeingFetched = () =>
    useSelector(isLastStateBeingFetched);
export const useIsVehiclesFetching = () =>
    useSelector((state: TRootState) =>
        areSearcherResultsBeingFetched(state, 'vehicles')
    );
export const useIsEmployeesFetching = () =>
    useSelector((state: TRootState) =>
        areSearcherResultsBeingFetched(state, 'employees')
    );

export const useIncludedNotPlanned = () => useSelector(getIncludeNotPlanned);
export const useOnlyNotAssigned = () => useSelector(getNotAssignedFilter);

export const useSourceSetDates = () => useSelector(getSourceSetDates);
export const useSourceSetTime = () => useSelector(getSourceSetTime);
export const useGridSourceSet = () => useSelector(getModeledGridSourceSet);
export const useSourceSet = () => useSelector(getDiscoveryMapSourceSet);
export const useSourceSetModel = () => useSelector(getSourceSetModel);
export const useSourceSetEntitiesIds = () =>
    useSelector(getGridSourceSetEntitiesIds);
export const useSourceSetId = () => useSelector(getGridSourceSetId);
export const useSourceSetElementStartDate = () =>
    useSelector(getSourceSetElementStartDate);
export const useSourceSetElementMonitoredId = () =>
    useSelector(getSourceSetElementMonitoredId);
export const useFrameElementMonitoredId = () =>
    useSelector(getFrameElementMonitoredId);
export const useFrameElementDate = () => useSelector(getFrameElementDate);
export const usePrivileges = () => useSelector(getPrivileges);
export const useSourceSetElementDate = () =>
    useSelector(getSourceSetElementDate);
export const useSelectedMonitoredObject = () =>
    useSelector(getSelectedMonitoredObject);
export const useSelectedMonitoredObjectTrail = () => useSelector(getTrail);
export const useDataSegmentsTrails = () => useSelector(getDataSegmentsTrails);

export const useGridCreatorLevel = () => useSelector(getGridCreatorLevel);
export const useIsGridFullscreen = () =>
    useSelector(isDiscoverUIGridFullscreen);
export const useGridObjectName = () => useSelector(getDataGridObjectName);
export const useGridFilters = () => useSelector(getGridFilters);
export const useIsAnalyticsModeEnabled = () =>
    useSelector(isAnalyticsModeEnabled);

export const useShouldMapBeInEditMode = () =>
    useSelector(shouldMapBeInEditMode);
export const useMapInDrawMode = () => useSelector(mapInDrawMode);
export const useFitToExtentIsPending = () =>
    useSelector(getMapFitToExtentIsPending);
export const useClustering = () => useSelector(getGeneralClustering);
export const useTimeLocked = () => useSelector(getTimeLock);
export const useLastClickCoordinate = () =>
    useSelector(getLastMapClickPosition);

export const useNearEventsRadius = () => useSelector(getNearEventsRadius);

export const useSourceSetMovieFramesIds = (id: string) =>
    useSelector((state: TRootState) => getSourceSetMovieFramesIds(state, id));

export const useFramesMoviesIds = () => useSelector(getFramesMoviesIds);

export const useLocation = () => useSelector(getPreviewLocation);

export const useLastState = () => useSelector(getPreviewLastState);

export const useDiscoveryEditMode = () =>
    useSelector(shouldDiscoveryBeInEditMode);

export const useIsSourceSetBeingFetched = () =>
    useSelector(isSourceSetBeingFetched);
export const useIsTrailBeingFetched = () => useSelector(isTrailBeingFetched);
export const useIsReportSetBeingFetched = () =>
    useSelector(isReportSetBeingFetched);
export const useDiscoveryUIDialog = () => useSelector(getDiscoveryUIDialog);
