import React, { ReactNode, useEffect, useRef, useState } from 'react';

import { Divider, LinearProgress, Paper } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { useSnackbar } from 'notistack';

import {
    enterPreviewMode,
    resetMode,
} from '../../../../../state/ui/customerService/bottomGrid';
import {
    fetchTasks,
    selectTask,
    setDates,
} from '../../../../../state/ui/customerService/bottomGrid/tasks';
import {
    useSelectedGridItemId,
    useSelectedClientsAndLocations,
} from '../../../../../state/ui/customerService/clientsAndLocations/index.hooks';
import {
    useTaskId,
    useTaskDates,
    useTasks,
    useTasksLoading,
    useTasksOnlyNotAssigned,
    useTasksOnlyNotPlanned,
    useTasksSourceSetModel,
} from '../../../../../state/ui/customerService/bottomGrid/tasks/index.hooks';
import {
    selectClient,
    selectLocation,
} from '../../../../../state/ui/customerService/clientsAndLocations';

import { useToolkitDispatch } from '../../../../../hooks';
import TranslationHelper from '../../../../../helpers/TranslationHelper';
import { errorMessageHandler } from '../../../../../helpers/errorMessageHandler';

import { useStyles } from '../../../Themable.hooks';

import IconButtonWithTooltip from '../../../../../components/IconButtonWithTooltip';
import PaneHeader from '../../../../../components/PaneHeader';
import SearchField from '../../../../../components/common/SearchField/SearchField';
import SourceSetGrid from '../../../../../components/SourceSetGrid';
import { ISourceSetEntity } from '../../../../../state/types';
import DateRangeDisplay from '../../../../../components/DateRangeDisplay';
import TasksCheckboxFilters from './TasksCheckboxFilters';
import CscExportButtons from '../../CscExportButtons/CscExportButtons';

interface IOwnProps {
    handleClose: () => void;
    titleComponent?: ReactNode;
}

const TasksGrid = ({ handleClose, titleComponent }: IOwnProps) => {
    const notInitialRender = useRef(false);

    const sourceSetModel = useTasksSourceSetModel();

    const [filter, setFilter] = useState(sourceSetModel?.quickFilter || '');
    const [gridApi, setGridApi] = useState(undefined);

    const dates = useTaskDates();
    const onlyNotAssignedFromStore = useTasksOnlyNotAssigned();
    const onlyNotPlannedFromStore = useTasksOnlyNotPlanned();
    const classes = useStyles();
    const tasks = useTasks();
    const loading = useTasksLoading();
    const taskId = useTaskId();
    const selectedGridItemId = useSelectedGridItemId();
    const selectedClientsAndLocations = useSelectedClientsAndLocations();

    const { enqueueSnackbar } = useSnackbar();
    const toolkitDispatch = useToolkitDispatch();

    useEffect(() => {
        if (!loading) {
            handleFetchTasks(dates.from, dates.to)
                ?.unwrap()
                .catch((error) => {
                    const message = errorMessageHandler(error.status)();
                    enqueueSnackbar(message, {
                        variant: 'error',
                    });
                });
        }
    }, [selectedClientsAndLocations]);

    useEffect(() => {
        if (notInitialRender.current) {
            toolkitDispatch(resetMode());
        } else {
            notInitialRender.current = true;
        }
    }, [selectedGridItemId]);

    const handleFetchTasks = (from: string, to: string) => {
        if (!selectedClientsAndLocations.length) {
            return;
        }
        toolkitDispatch(setDates({ from, to }));
        const fetchTasksParams = () => {
            return onlyNotPlannedFromStore
                ? {
                      selectedClientsAndLocations,
                      onlyNotAssigned: onlyNotAssignedFromStore,
                      includeNotPlanned: true,
                  }
                : {
                      selectedClientsAndLocations,
                      from,
                      to,
                      onlyNotAssigned: onlyNotAssignedFromStore,
                      includeNotPlanned: true,
                  };
        };
        return toolkitDispatch(fetchTasks(fetchTasksParams()));
    };

    const renderRightCustomControls = () => {
        return (
            <>
                <CscExportButtons api={gridApi} prefix="tasks" />
                <IconButtonWithTooltip
                    title={TranslationHelper.translate('Close')}
                    onClick={() => handleClose()}
                >
                    <CloseIcon />
                </IconButtonWithTooltip>
            </>
        );
    };

    const handleCellInteraction = (data: ISourceSetEntity) => {
        const locationId = selectedClientsAndLocations.filter(
            (item) => item.id === data.locationId?.toString()
        )[0]?.locationId;
        toolkitDispatch(
            selectLocation({
                id: locationId,
                externalId: data.locationId?.toString(),
            })
        );
        toolkitDispatch(selectClient(data.clientId));
        toolkitDispatch(enterPreviewMode());
        toolkitDispatch(selectTask(data.id));
    };
    const handleSetApiGrid = (
        gridApi: any,
        gridColumnApi: any | undefined = undefined
    ) => {
        setGridApi(gridApi);
    };
    return (
        <Paper className={classes.gridPane}>
            <div className={classes.wrapper}>
                <PaneHeader
                    title={TranslationHelper.translate('Tasks')}
                    titleComponent={titleComponent}
                    renderLeftCustomControls={() => (
                        <>
                            <SearchField
                                value={filter}
                                filterChange={setFilter}
                            />
                            <DateRangeDisplay
                                onDateChange={handleFetchTasks}
                                sourceSetId={tasks?.id}
                                dates={dates}
                                datesFormat={'short'}
                                small
                            />
                            <TasksCheckboxFilters dates={dates} />
                        </>
                    )}
                    renderRightCustomControls={() =>
                        renderRightCustomControls()
                    }
                />
                {!tasks || loading ? <LinearProgress /> : <Divider />}
                {tasks && (
                    <SourceSetGrid
                        sourceSet={tasks}
                        cellInteractionHandler={handleCellInteraction}
                        quickFilterValue={filter}
                        setGridApi={handleSetApiGrid}
                        sourceSetModel={sourceSetModel}
                        selectedSourceSetElementId={String(taskId)}
                    />
                )}
            </div>
        </Paper>
    );
};

export default TasksGrid;
