import { Map as olMap } from 'ol'; // Assuming you're using OpenLayers
import { Feature } from 'ol';
import { Geometry } from 'ol/geom';
import { DrawEvent } from 'ol/interaction/Draw';
import VectorSource from 'ol/source/Vector';

export const FEATURE_SELECTED = 1;
export const FEATURE_NOT_SELECTED = 0;
const ANGLE = Math.PI / 4;

export const handleSelectOnClick = (
    map: olMap,
    pixel: number[],
    selectItems: (items: string[], id: string) => void,
    selectedItems: string[]
) => {
    const features: Feature<Geometry>[] = map.getFeaturesAtPixel(
        pixel
    ) as Feature<Geometry>[];
    if (features.length > 0) {
        const feature = features[0];
        const id = feature?.get('data')?.id;

        if (id != null) {
            const isSelected = feature.get('selected') === FEATURE_SELECTED;

            const items = isSelected
                ? selectedItems.filter((item) => item !== id)
                : [...selectedItems, id];
            const item = isSelected ? -Number(id) : id;
            selectItems(items, item);

            feature.set(
                'selected',
                isSelected ? FEATURE_NOT_SELECTED : FEATURE_SELECTED
            );
        }
    }
};

export const handleSelectSingle = (
    map: olMap,
    pixel: number[],
    source: VectorSource<Geometry> | null,
    selectItems: (items: string[], id: string) => void
) => {
    const features: Feature<Geometry>[] = map.getFeaturesAtPixel(
        pixel
    ) as Feature<Geometry>[];
    if (features.length > 0) {
        const feature = features[0];
        const id = feature?.get('data')?.id;
        if (id != null) {
            //map
            const features = source?.getFeatures();
            features?.forEach((feature) => {
                feature.unset('selected');
            });

            const isSelected = feature.get('selected') === FEATURE_SELECTED;

            //state/grid
            const item = isSelected ? -Number(id) : id;

            selectItems([id], item);
            feature.set(
                'selected',
                isSelected ? FEATURE_NOT_SELECTED : FEATURE_SELECTED
            );
        }
    }
};

//drawing function
export const handleDrawEnd = (
    event: DrawEvent,
    source: VectorSource<Geometry> | null,
    selectItems: (items: string[], id: string) => void,
    setDrawing: (params: boolean) => void
) => {
    const extent = event.feature.getGeometry()?.getExtent();
    const arr: string[] = [];
    source?.forEachFeature((feature) => {
        const geometry = feature.getGeometry();

        if (extent && geometry?.intersectsExtent(extent)) {
            feature.set('selected', FEATURE_SELECTED);
            if (feature.get('data').id) {
                arr.push(feature.get('data').id);
            }
        } else {
            feature.set('selected', FEATURE_NOT_SELECTED);
        }
    });

    selectItems(arr, arr[0]);
    setDrawing(false);
};

export const STYLE = {
    'shape-points': 4,
    'shape-radius': 12,
    'shape-stroke-color': [
        'match',
        ['get', 'selected'],
        FEATURE_SELECTED,
        'red', // Border color when selected
        'transparent', // Border color when not selected
    ],
    'shape-stroke-width': 3,

    'shape-fill-color': [
        'color',
        ['get', 'red'],
        ['get', 'green'],
        ['get', 'blue'],
    ],
    'shape-angle': ANGLE,
};
