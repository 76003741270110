import React, { useState } from 'react';

import {
    ColDef,
    GridApi,
    GridReadyEvent,
    IDatasource,
    IGetRowsParams,
    IServerSideGetRowsParams,
    GridOptions,
    ICellRendererParams,
} from 'ag-grid-community';

import { LinearProgress, Paper } from '@material-ui/core';
import { default as PlusIcon } from '@material-ui/icons/Add';

import { useStyles } from '../../../../Themable.hooks';

import TranslationHelper from '../../../../../../helpers/TranslationHelper';

import {
    enterCreateMode,
    fetchReportSchedules,
} from '../../../../../../state/ui/reportsSlice';
import { useUserSettings } from '../../../../../../state/user/index.hooks';

import { useAppDispatch, useToolkitDispatch } from '../../../../../../hooks';

import { reportsDetailCellRenderer } from '../../../../utils/reportDetailCellRenderer';

import { gridValueGetter } from '../../../../../../components/SourceSetGrid/_utils';
import InfiniteRowModelGrid from '../../../../../../components/InfiniteRowModelGrid';
import PaneHeader from '../../../../../../components/PaneHeader';
import IconButtonWithTooltip from '../../../../../../components/IconButtonWithTooltip';
import ActionsButtons from './components/cellRenderers/ActionsButtons';
import StatusIcon from './components/cellRenderers/StatusIcon';
import { IReportSchedule } from '../../../../../../state/ui/reportsSlice';

const frameworkComponents = {
    reportsDetailCellRenderer,
    actions: ActionsButtons,
    statusIcon: ({ data }: ICellRendererParams) => {
        return <StatusIcon data={data as IReportSchedule} />;
    },
};

interface IOwnProps {
    gridApi: GridApi | null;
    setGridApi: (gridApi: GridApi | null) => void;
}
const SchedulesGrid = ({ gridApi, setGridApi }: IOwnProps) => {
    const [dataSourceLoading, setDataSourceLoading] = useState<boolean>(true);

    const classes = useStyles();
    const userSettings = useUserSettings();

    const toolkitDispatch = useToolkitDispatch();
    const dispatch = useAppDispatch();

    const colDefs: ColDef[] = [
        {
            field: 'repetition.endBoundaryDate',
            headerName: TranslationHelper.translate('Status'),
            flex: 0.5,
            cellRenderer: 'statusIcon',
        },
        {
            field: 'type.name',
            headerName: TranslationHelper.translate('Type'),
        },
        {
            field: 'repetition.interval',
            headerName: TranslationHelper.translate('Periodicity'),
            flex: 0.5,
            valueGetter: (params) =>
                TranslationHelper.translate(params.data?.repetition.interval),
        },
        {
            headerName: TranslationHelper.translate('Objects'),
            resizable: true,
            cellRenderer: 'reportsDetailCellRenderer',
            flex: 2,
        },

        {
            field: 'repetition.startBoundaryDate',
            headerName: TranslationHelper.translate('Start'),
            valueGetter: (params) =>
                gridValueGetter({
                    value: params.data?.repetition.startBoundaryDate,
                    type: 'date',
                    userSettings: userSettings,
                    formatting: { pattern: 'short' },
                }),
        },
        {
            field: 'repetition.endBoundaryDate',
            headerName: TranslationHelper.translate('End'),
            valueGetter: (params) =>
                gridValueGetter({
                    value: params.data?.repetition.endBoundaryDate,
                    type: 'date',
                    userSettings: userSettings,
                    formatting: { pattern: 'short' },
                }),
        },
        {
            field: 'nextDueDate',
            headerName: TranslationHelper.translate('Next report date'),
            valueGetter: (params) =>
                gridValueGetter({
                    value: params.data?.nextDueDate,
                    type: 'date',
                    userSettings: userSettings,
                    formatting: { pattern: 'short' },
                }),
        },

        {
            field: 'id',
            headerName: TranslationHelper.translate('Actions'),
            pinned: 'right',
            cellRenderer: 'actions',
        },
    ];

    const getRowsData = (params: IGetRowsParams) => ({
        start: params.startRow,
        end: params.endRow,
    });

    const getRows = (params: IGetRowsParams | IServerSideGetRowsParams) => {
        setDataSourceLoading(true);

        gridApi?.hideOverlay();
        getRowsData(params as IGetRowsParams);

        toolkitDispatch(fetchReportSchedules())
            .unwrap()
            .then((data) => {
                setDataSourceLoading(false);
                params.successCallback(data, data.length);
                if (data.length === 0) {
                    gridApi?.showNoRowsOverlay();
                }
            });
    };
    const reportsDataSource: IDatasource = { getRows };

    const setDataSource = (api: GridApi) => {
        api.setDatasource(reportsDataSource);
    };
    const onGridReady = ({ api }: GridReadyEvent) => {
        setGridApi(api);
        setDataSource(api);
    };
    const renderRightCustomControls = () => {
        return (
            <IconButtonWithTooltip
                title={TranslationHelper.translate('Add schedule')}
                onClick={() => dispatch(enterCreateMode())}
            >
                <PlusIcon />
            </IconButtonWithTooltip>
        );
    };

    const gridProps: GridOptions = {
        frameworkComponents,
    };

    return (
        <Paper className={classes.gridPane}>
            <div className={classes.wrapper}>
                <PaneHeader
                    title={TranslationHelper.translate('Reporting schedules')}
                    renderRightCustomControls={
                        gridApi ? () => renderRightCustomControls() : undefined
                    }
                />
                {dataSourceLoading && <LinearProgress />}

                <InfiniteRowModelGrid
                    columnDefs={colDefs}
                    onGridReady={onGridReady}
                    {...gridProps}
                />
            </div>
        </Paper>
    );
};

export default SchedulesGrid;
