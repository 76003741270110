import React from 'react';
import { Route, Switch } from 'react-router-dom';

import EmailVerification from '../../../pages/EmailVerification';
import Page404 from '../../../pages/error/Page404';
import LoginPageContainer from '../../../pages/login/LoginPageContainer';
import PasswordResetPage from '../../../pages/PasswordResetPage';
import MigrationEmailVerification from '../../../pages/login/Migration/MigrationEmailVerification';
import { MigrationResetPassword } from '../../../pages/login/Migration/MigrationResetPassword';

import ConfigPreloadGuard from '../../ConfigPreloadGuard/ConfigPreloadGuard';
import BasicApp from './_shared/BasicApp';
import RootWrapper from './_shared/RootWrapper';
import { ThemeProvider, alpha, createTheme } from '@mui/material/styles';
import {
    alpha as alphav4,
    createTheme as createThemev4,
    ThemeProvider as ThemeProviderv4,
} from '@material-ui/core';

import Color from 'color';
import colorString from 'color-string';
import AppConfig from '../../../constants/AppConfig';
import { DEFAULT_PRIMARY_COLOR } from '../../../constants/ColorConst';

const DefaultApp = () => <BasicApp />;

const DefaultRoot = () => {
    const brandingTheme =
        AppConfig && AppConfig.instance.getConfigKey('th') === 'branding';
    const userBranding =
        brandingTheme &&
        AppConfig.instance.getConfigKey(AppConfig.PROPERTY_BRANDING);
    const userPrimaryColor =
        (userBranding && userBranding.primaryColor) || DEFAULT_PRIMARY_COLOR;
    const themeMainColor = colorString.get(userPrimaryColor)
        ? userPrimaryColor
        : DEFAULT_PRIMARY_COLOR;
    const theme = createTheme({
        palette: {
            primary: {
                main: themeMainColor,
            },
        },
        components: {
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        '&.Mui-selected': {
                            background: themeMainColor,
                            color: Color(themeMainColor).isLight()
                                ? '#000'
                                : '#fff',
                        },
                        '&.Mui-hover': {
                            background: alpha(themeMainColor, 0.5),
                        },
                        '&$selected:hover': {
                            background: themeMainColor,
                        },
                    },
                },
            },
            MuiListItem: {
                styleOverrides: {
                    button: {
                        '&Mui-hover': {
                            backgroundColor: alpha(themeMainColor, 0.5),
                        },
                    },
                },
            },
        },
    });
    //TODO remove after v4 to v5 mui migration done
    const themev4 = createThemev4({
        palette: {
            primary: {
                main: themeMainColor,
            },
        },
        overrides: {
            MuiMenuItem: {
                root: {
                    '&$selected': {
                        background: themeMainColor,
                        color: Color(themeMainColor).isLight()
                            ? '#000'
                            : '#fff',
                    },
                    '&:hover': {
                        background: alphav4(themeMainColor, 0.5),
                    },
                    '&$selected:hover': {
                        background: themeMainColor,
                    },
                },
            },
            MuiListItem: {
                button: {
                    '&:hover': {
                        backgroundColor: alphav4(themeMainColor, 0.5),
                    },
                },
            },
        },
    });
    return (
        <ThemeProvider theme={theme}>
            <ThemeProviderv4 theme={themev4}>
                <RootWrapper>
                    <ConfigPreloadGuard fetchOnlyTranslations={true}>
                        <Switch>
                            <Route
                                exact={true}
                                path="/401"
                                component={LoginPageContainer}
                            />
                            <Route
                                exact={true}
                                path="/404"
                                component={Page404}
                            />
                            <Route
                                exact={true}
                                path="/verification"
                                component={EmailVerification}
                            />
                            <Route
                                path="/password-reset"
                                component={PasswordResetPage}
                            />
                            <Route
                                exact={true}
                                path="/user-migration"
                                component={LoginPageContainer}
                            />
                            <Route
                                exact={true}
                                path="/user-migration/verification"
                                component={MigrationEmailVerification}
                            />
                            <Route
                                exact={true}
                                path="/user-migration/login-confirmation"
                                component={MigrationResetPassword}
                            />
                            <Route path="/" component={DefaultApp} />
                        </Switch>
                    </ConfigPreloadGuard>
                </RootWrapper>
            </ThemeProviderv4>
        </ThemeProvider>
    );
};

export default DefaultRoot;
