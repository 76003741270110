import React, { useState, useEffect, useReducer } from 'react';

import { ValidationError } from 'yup';

import moment, { Moment } from 'moment';

import { useSnackbar } from 'notistack';

import SaveIcon from '@material-ui/icons/Save';
import { LinearProgress, TextField } from '@material-ui/core';

import { useStyles } from '../../../../Themable.hooks';

import { useLanguage } from '../../../../../../state/login/index.hooks';
import {
    fetchReportsDefinitions,
    generateReport,
    INewReport,
    IReportsDefinition,
} from '../../../../../../state/ui/reportsSlice';

import { useToolkitDispatch } from '../../../../../../hooks';

import TranslationHelper from '../../../../../../helpers/TranslationHelper';
import TimeFormatter from '../../../../../../helpers/TimeFormatter';
import { errorMessageHandler } from '../../../../../../helpers/errorMessageHandler';
import { renderMenuItems } from '../../../../../../helpers/renderMenuItems';

import { reportsSchema } from '../../../../../../schemas';

import { useReportsDefinitions } from '../../../../../../state/ui/reportsSlice/index.hooks';

import IconButtonWithTooltip from '../../../../../../components/IconButtonWithTooltip/IconButtonWithTooltip';
import PaneHeader from '../../../../../../components/PaneHeader/PaneHeader';
import DatePresets from '../../../../../../components/DatePresets';
import ObjectsSection from '../../../../../../components/ObjectsSection';

interface IOwnProps {
    title: string;
    tooltip: string;
    handleClose: () => void;
}

interface IInitialState {
    reportType: { typeId: number | ''; name: string; templateId: number | '' };
    dateTo: Moment | null;
    dateFrom: Moment | null;
    objects: IMappedObject[];
}

export interface IMappedObject {
    id: number;
    key: string;
    name: string;
}
export interface IErrors {
    ['reportType.templateId']?: string;
    dateTo?: string;
    dateFrom?: string;
    objects?: string;
}

const ReportsForm = ({ title, tooltip, handleClose }: IOwnProps) => {
    const initialState: IInitialState = {
        reportType: {
            typeId: '',
            name: '',
            templateId: '',
        },
        dateFrom: moment().startOf('day'),
        dateTo: moment().endOf('day'),
        objects: [],
    };
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errors, setErrors] = useState<IErrors>({});

    const toolkitDispatch = useToolkitDispatch();

    const { enqueueSnackbar } = useSnackbar();
    const reportsDefinitions = useReportsDefinitions();
    const language = useLanguage();

    const [formValues, setFormValues] = useReducer(
        (curVals, newVals) => ({ ...curVals, ...newVals }),
        initialState
    );

    const { reportType, dateFrom, dateTo, objects } = formValues;

    const handleFormChange = <T,>(name: string, value: T) => {
        setFormValues({ [name]: value });
    };

    useEffect(() => {
        setLoading(true);
        toolkitDispatch(fetchReportsDefinitions())
            .unwrap()
            .then((data) => {
                if (data.length === 1) {
                    handleFormChange('reportType', data[0]);
                }
                setLoading(false);
            });
    }, []);

    const getRightCustomControls = (submitting: boolean) => (
        <IconButtonWithTooltip
            title={tooltip}
            onClick={handleValues}
            disabled={submitting}
        >
            <SaveIcon />
        </IconButtonWithTooltip>
    );
    const validateFields = () => {
        reportsSchema
            .validate(formValues, { abortEarly: false })
            .then(() => {
                setErrors({});
            })

            .catch((err: ValidationError) => {
                const newErrors = err.inner.reduce((acc, error) => {
                    acc[error.path || ''] = TranslationHelper.translate(
                        error.errors[0]
                    );
                    return acc;
                }, {});
                setErrors(newErrors);
            });
    };

    const prepareData = (data: IInitialState) => {
        const requestData: INewReport = {
            subjects: data.objects.map((o) => o.key).join(','),
            to: TimeFormatter.toISOString(dateTo),
            from: TimeFormatter.toISOString(dateFrom),
            type: Number(data.reportType.typeId),
            template: String(data.reportType.templateId),
            language,
        };
        return requestData;
    };

    const showNotification = (success: boolean, message: string) => {
        enqueueSnackbar(message, {
            variant: success ? 'success' : 'error',
        });
    };

    const handleValues = async () => {
        const isDataValid = await reportsSchema.isValid(formValues);
        if (isDataValid) {
            setIsSubmitting(true);

            const handleClearForm = () => {
                setIsSubmitting(false);
                setErrors({});
                handleClose();
            };
            const data = prepareData(formValues);
            toolkitDispatch(generateReport(data))
                .unwrap()
                .then(() => {
                    showNotification(
                        true,
                        TranslationHelper.translate('Report created')
                    );

                    handleClearForm();
                })
                .catch((error) => {
                    const message = errorMessageHandler(error.status)();
                    showNotification(false, message);
                    handleClearForm();
                });
        } else {
            validateFields();
            setIsSubmitting(false);
        }
    };

    const renderForm = () => {
        return reportType.templateId ? (
            <>
                <ObjectsSection
                    objects={objects}
                    handleFormChange={handleFormChange}
                    errors={errors?.objects}
                />

                <DatePresets
                    dateFrom={dateFrom}
                    dateTo={dateTo}
                    handleFormChange={handleFormChange}
                    errors={errors}
                />
            </>
        ) : (
            <></>
        );
    };

    return (
        <form onSubmit={handleValues}>
            <PaneHeader
                onCloseClick={handleClose}
                title={title}
                renderRightCustomControls={() =>
                    getRightCustomControls(isSubmitting)
                }
                submitting={isSubmitting}
            />
            {loading ? (
                <LinearProgress />
            ) : (
                <div className={classes.formWrapper}>
                    <TextField
                        error={!!errors?.['reportType.templateId']}
                        helperText={errors?.['reportType.templateId']}
                        name="reportType"
                        label={TranslationHelper.translate('Report')}
                        value={reportType.templateId}
                        onChange={(e) => {
                            if (reportsDefinitions) {
                                const report = reportsDefinitions.filter(
                                    (definition) =>
                                        definition.templateId ===
                                        Number(e.target.value)
                                )[0];
                                handleFormChange('reportType', report);
                            }
                        }}
                        select={true}
                        fullWidth={true}
                        required={true}
                        data-testid={'reportType'}
                    >
                        {renderMenuItems(
                            reportsDefinitions || [],
                            (item: IReportsDefinition) => {
                                return {
                                    id: String(item.templateId),
                                    name: item.name,
                                };
                            }
                        )}
                    </TextField>
                    {renderForm()}
                </div>
            )}
        </form>
    );
};

export default ReportsForm;
