import Circle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import RegularShape from 'ol/style/RegularShape';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import { Icon } from 'ol/style';

import IconGenerator from '../../../../../helpers/IconGenerator/IconGenerator';

import {
    IIcon,
    ISourceSet,
    TSourceSetEntity,
} from '../../../../../state/types';

import { createClusterImage } from './clustering';

const A = 20;
const ICON_IMAGE_RADIUS = 17;
const ICON_IMAGE_SIZE = ICON_IMAGE_RADIUS * 2;
const SIZE_BREAKPOINT = 10000;
const SQRT2 = Math.sqrt(2);

export const ICON_STYLES = {
    base: new Style({
        image: new Circle({
            radius: 10,
            fill: new Fill({ color: 'yellow' }),
            stroke: new Stroke({ color: 'red', width: 1 }),
        }),
    }),
    baseSelected: new Style({
        image: new Circle({
            radius: 10,
            fill: new Fill({ color: 'orange' }),
            stroke: new Stroke({ color: 'red', width: 1 }),
        }),
    }),
    rectangle: new Style({
        image: new RegularShape({
            fill: new Fill({ color: [0, 0, 0, 0] }),
            stroke: new Stroke({
                color: 'red',
                width: 3,
                lineDash: [A / 2, A, A / 2],
            }),
            points: 4,
            radius: A * SQRT2,
            angle: Math.PI / 4,
        }),
    }),
    trail: new Style({
        stroke: new Stroke({
            color: 'blue',
            width: 5,
        }),
    }),
    location: IconGenerator.getDiscoveryIconStyle(
        { id: 'location', name: 'location', params: {} },
        'DISCOVERY_LOCATION'
    ),
};

const abstractGetIconDefinition = (data?: TSourceSetEntity | ISourceSet) =>
    data?._meta?.icon || undefined;

const getIconDefinition = (
    sourceSet?: ISourceSet,
    entity?: TSourceSetEntity
): IIcon | undefined => {
    const sourceSetIconDefinition = abstractGetIconDefinition(sourceSet);
    const entityIconDefinition = abstractGetIconDefinition(entity);

    if (sourceSetIconDefinition || entityIconDefinition) {
        const iconData = entity as { name?: string };
        return {
            ...sourceSetIconDefinition,
            ...entityIconDefinition,
            ...(sourceSet && sourceSet.id && { sourceSetId: sourceSet.id }),
            ...(iconData && { data: iconData }),
        } as IIcon;
    }

    return;
};

export const getIconStyle = (
    sourceSet?: ISourceSet,
    entity?: TSourceSetEntity
) => {
    const iconDef = getIconDefinition(sourceSet, entity);

    return (
        (iconDef && IconGenerator.getDiscoveryIconStyle(iconDef)) ||
        ICON_STYLES.base
    );
};

export const getIconImage = (size: number) => {
    return new Icon({
        src: createClusterImage(
            ICON_IMAGE_RADIUS,
            size < SIZE_BREAKPOINT ? size.toString() : '9999+'
        ),
        height: ICON_IMAGE_SIZE,
        width: ICON_IMAGE_SIZE,
    });
};
