import React, { useEffect } from 'react';

import Cookie from 'cookie';

import { useHistory } from 'react-router-dom';

import { useMediaQuery } from '@material-ui/core';

import LoginPage from './LoginPage';

import { fetchAds, loginUserSuccessAction } from '../../state/login';
import { useNetworkStatus } from '../../state/app/statusSlice/index.hooks';
import { IPrivileges } from '../../state/auth';
import { usePrivileges } from '../../state/auth/index.hooks';
import { useRequiredActions } from '../../state/auth/index.hooks';
import { showLoginMigrationDialog } from '../../state/ui/dialog';
import { fetchMonitoredFuelTanks } from '../../state/app/fuelTanks';
import { setVideoParams, toggleVideoDialog } from '../../state/ui/videoUrl';
import {
    useCustomers,
    useFromPath,
    useLanguage,
    useLanguages,
} from '../../state/login/index.hooks';

import { handleCustomers } from '../../services/Customers';
import { ILinkVideoArgs } from '../../services/discovery/_shared/urlMakers';

import TranslationHelper from '../../helpers/TranslationHelper';
import { paramsToObject } from '../../helpers/paramsToObject';
import { useAppDispatch } from '../../hooks';

import FullScreenSpinner from '../../components/FullScreenSpinner/FullScreenSpinner';

import { MOBILE } from '../../constants/dictionaries/ScreenSizeConst';
import AppConfig from '../../constants/AppConfig';
import { TLoginRequiredActions } from 'src/app/state/login/types';

const LoginPageContainer = () => {
    const networkStatus = useNetworkStatus();
    const language = useLanguage();
    const languages = useLanguages();
    const customers = useCustomers();

    const history = useHistory();
    const isMobile = useMediaQuery(MOBILE);

    const dispatch = useAppDispatch();

    const requiredActions = useRequiredActions();

    const fromPath = useFromPath();
    const userPrivileges = usePrivileges();

    useEffect(() => {
        dispatch(fetchAds(window.location.host.replace(/^www\./i, '')));
        const cookies = Cookie.parse(document.cookie);

        if (
            cookies.language &&
            cookies['XTrack-Web-Token'] &&
            cookies.login &&
            cookies.customer
        ) {
            if (requiredActions?.length) {
                handleActions(requiredActions);
            } else {
                dispatch(loginUserSuccessAction());
            }
        } else {
            dispatch(handleCustomers());
        }
    }, []);

    const handleActions = (actions: TLoginRequiredActions[]) => {
        if (actions.includes('MIGRATE_LOGIN')) {
            history.replace('/user-migration');
            dispatch(showLoginMigrationDialog({ redirectAfterLogin }));
            return;
        }
        if (actions.includes('CONFIRM_LOGIN_MIGRATION')) {
            history.replace('/user-migration/verification');
            return;
        }
    };

    const redirectAfterLogin = () => {
        dispatch(loginUserSuccessAction());

        if (isMobile) {
            history.replace('/discovery');
            return;
        }
        if (fromPath === '/player') {
            const urlParams = new URLSearchParams(window.location.search);
            const entries = urlParams.entries();
            const params = paramsToObject(entries) as ILinkVideoArgs;
            dispatch(setVideoParams(params));
            dispatch(toggleVideoDialog());
        }
        if (fromPath === '/discovery' || fromPath === '/reports') {
            const urlParams = new URLSearchParams(window.location.search);
            const entries = urlParams.entries();
            const params = paramsToObject(entries) as {
                action?: string;
            };

            if (params.action) {
                return;
            }
        }

        if (
            fromPath &&
            fromPath !== '/' &&
            fromPath !== '/login' &&
            fromPath !== '/user-migration' &&
            fromPath !== '/password-reset'
        ) {
            history.replace(fromPath);
            return;
        }
        if (
            userPrivileges &&
            userPrivileges.dashboardContext &&
            AppConfig.instance.getConfigKey('dashboardEnabled')
        ) {
            dispatch(
                fetchMonitoredFuelTanks((fuelTanks) => {
                    fuelTanks && fuelTanks.total > 0
                        ? history.replace('/dashboard')
                        : history.replace(chooseInitialPage(userPrivileges));
                })
            );
        } else {
            history.replace(chooseInitialPage(userPrivileges));
        }
    };

    const chooseInitialPage = (privileges: IPrivileges) => {
        privileges = privileges || {};
        let firstPage;

        if (privileges.discoveryContext) {
            firstPage = '/discovery';
        } else if (privileges.reportsContext) {
            firstPage = '/reports';
        } else if (privileges.registersContext) {
            firstPage = '/registers';
        } else if (privileges.alertsContext) {
            firstPage = '/alerts';
        } else {
            firstPage = '/discovery';
        }

        return firstPage;
    };

    const getMessage = () => {
        return networkStatus && networkStatus !== 200
            ? TranslationHelper.translate(
                  'An error has occurred, please try again later'
              )
            : '';
    };

    if (customers && language && languages) {
        return (
            <LoginPage
                redirectAfterLogin={redirectAfterLogin}
                handleActions={handleActions}
            />
        );
    } else if (requiredActions?.length) {
        return <></>;
    } else {
        return <FullScreenSpinner message={getMessage()} />;
    }
};

export default LoginPageContainer;
