import { useSelector } from 'react-redux';
import {
    getSelectedTaskId,
    getTask,
    getTaskPaneLoading,
    getTaskDates,
    getTasksAsSourceSet,
    getTasksLoading,
    getTasksOnlyNotAssigned,
    getTasksOnlyNotPlanned,
    getTasksSourceSetModel,
    getOpenSectionTasks,
    getOpenSectionTaskAttachments,
} from '.';

export const useTasks = () => useSelector(getTasksAsSourceSet);
export const useTasksLoading = () => useSelector(getTasksLoading);
export const useTask = () => useSelector(getTask);
export const useTaskId = () => useSelector(getSelectedTaskId);
export const useTaskPaneLoading = () => useSelector(getTaskPaneLoading);
export const useTasksSourceSetModel = () => useSelector(getTasksSourceSetModel);
export const useTasksOnlyNotAssigned = () =>
    useSelector(getTasksOnlyNotAssigned);
export const useTasksOnlyNotPlanned = () => useSelector(getTasksOnlyNotPlanned);
export const useTaskDates = () => useSelector(getTaskDates);
export const useOpenSectionTasks = () => useSelector(getOpenSectionTasks);
export const useOpenSectionTaskAttachments = () =>
    useSelector(getOpenSectionTaskAttachments);
