import React, { useEffect } from 'react';

import moment from 'moment';

import { LinearProgress } from '@material-ui/core';
import { FaRoute } from 'react-icons/fa';
import {
    fetchTask,
    toggleCollapseTaskAttachments,
    toggleCollapseTasks,
} from '../../../../../../../state/ui/customerService/bottomGrid/tasks';
import {
    useSelectedClient,
    useSelectedLocation,
} from '../../../../../../../state/ui/customerService/clientsAndLocations/index.hooks';
import {
    useOpenSectionTaskAttachments,
    useOpenSectionTasks,
    useTask,
    useTaskId,
    useTaskPaneLoading,
} from '../../../../../../../state/ui/customerService/bottomGrid/tasks/index.hooks';
import {
    enterClientEditMode,
    enterLocationAddMode,
    enterLocationEditMode,
    resetMode,
} from '../../../../../../../state/ui/customerService/bottomGrid';
import {
    useOpenSectionContract,
    useOpenSectionContracts,
} from '../../../../../../../state/ui/customerService/bottomGrid/contractItems/index.hooks';
import {
    toggleCollapseContract,
    toggleCollapseContracts,
} from '../../../../../../../state/ui/customerService/bottomGrid/contractItems';

import TranslationHelper from '../../../../../../../helpers/TranslationHelper';
import { useToolkitDispatch } from '../../../../../../../hooks';
import { handleQueryData } from '../../../../../../../helpers/replacers';

import PaneHeader from '../../../../../../../components/PaneHeader/PaneHeader';
import { CollapseListItem } from '../../../../../../../components/CollapseListItem/CollapseListItem';
import TaskForm from '../TaskForm';
import AttachmentsSection from '../../../../../../../components/AttachmentsSection';
import ContractItemForm from '../../ContractItems/ContractItemForm';
import ContractForm from '../../Contract/ContractForm';
import {
    BLANK_FORM_CONTRACT,
    BLANK_FORM_CONTRACT_ITEM,
    BLANK_FORM_TASK,
    BLANK_FORM_LOCATION,
} from '../../ClientsAndLocations/blankForms';
import ClientSection from '../../ClientsAndLocations/ClientSection';
import LocationSection from '../../ClientsAndLocations/LocationSection';
import IconButtonWithTooltip from '../../../../../../../components/IconButtonWithTooltip/IconButtonWithTooltip';

const TaskPreview = () => {
    const toolkitDispatch = useToolkitDispatch();

    const client = useSelectedClient();
    const location = useSelectedLocation();
    const task = useTask();
    const contractItem = task?.contractItem;
    const contract = task?.contractItem?.contract;

    const openSectionTasks = useOpenSectionTasks();
    const openSectionTaskAttachments = useOpenSectionTaskAttachments();
    const openSectionContracts = useOpenSectionContracts();
    const openSectionContract = useOpenSectionContract();

    const taskId = useTaskId();
    const paneLoading = useTaskPaneLoading();
    const handleClose = () => {
        toolkitDispatch(resetMode());
    };

    useEffect(() => {
        taskId && toolkitDispatch(fetchTask(taskId));
    }, [taskId]);

    const handleToggleCollapseTasks = () => {
        toolkitDispatch(toggleCollapseTasks());
    };

    const handleToggleCollapseTaskAttachments = () => {
        toolkitDispatch(toggleCollapseTaskAttachments());
    };
    const handleToggleCollapseContracts = () => {
        toolkitDispatch(toggleCollapseContracts());
    };

    const handleToggleCollapseContract = () => {
        toolkitDispatch(toggleCollapseContract());
    };

    const handleEditClient = () => {
        toolkitDispatch(enterClientEditMode());
    };

    const handleEditLocation = () => {
        toolkitDispatch(enterLocationEditMode());
    };

    const handleAddLocation = () => {
        toolkitDispatch(enterLocationAddMode());
    };

    const handleShowEvent = () => {
        const data = {
            action: {
                type: 'showEventFromTask',
                params: {
                    id: taskId,
                },
            },
        };
        const query = handleQueryData(data, false);
        window.open(`/discovery?action=${query}`, '_blank');
    };
    const renderRightCustomControls = () => {
        return task &&
            moment(task.plannedOn).isSameOrBefore(moment(), 'days') ? (
            <IconButtonWithTooltip
                title={TranslationHelper.translate('Show event')}
                onClick={handleShowEvent}
            >
                <FaRoute />
            </IconButtonWithTooltip>
        ) : (
            <></>
        );
    };
    const initialValues = {
        client,
        location: location ? { ...BLANK_FORM_LOCATION, ...location } : null,
        task: task ? { ...BLANK_FORM_TASK, ...task } : null,
        contractItem: contractItem
            ? { ...BLANK_FORM_CONTRACT_ITEM, ...contractItem }
            : null,
        contract: contract ? { ...BLANK_FORM_CONTRACT, ...contract } : null,
    };

    return (
        <div>
            <PaneHeader
                onCloseClick={handleClose}
                title={TranslationHelper.translate('Task')}
                renderRightCustomControls={renderRightCustomControls}
            />
            {!task || paneLoading ? (
                <LinearProgress />
            ) : (
                <div>
                    {initialValues?.task && (
                        <CollapseListItem
                            title="Task"
                            openSection={openSectionTasks}
                            handleToggleCollapse={handleToggleCollapseTasks}
                        >
                            <TaskForm initialValues={initialValues.task} />
                        </CollapseListItem>
                    )}
                    {initialValues?.task?.attachments?.length && (
                        <CollapseListItem
                            title="Attachments"
                            openSection={openSectionTaskAttachments}
                            handleToggleCollapse={
                                handleToggleCollapseTaskAttachments
                            }
                        >
                            <AttachmentsSection
                                attachments={initialValues.task.attachments}
                                api={`/rest/api/tasks/${initialValues.task.id}/attachments/`}
                            />
                        </CollapseListItem>
                    )}
                    {initialValues?.contractItem && (
                        <CollapseListItem
                            title="Contract item"
                            openSection={openSectionContracts}
                            handleToggleCollapse={handleToggleCollapseContracts}
                        >
                            <ContractItemForm
                                initialValues={initialValues.contractItem}
                            />
                        </CollapseListItem>
                    )}
                    {initialValues?.contract && (
                        <CollapseListItem
                            title="Contract"
                            openSection={openSectionContract}
                            handleToggleCollapse={handleToggleCollapseContract}
                        >
                            <ContractForm
                                initialValues={initialValues.contract}
                            />
                        </CollapseListItem>
                    )}
                    <ClientSection
                        client={initialValues.client}
                        handleEditClient={handleEditClient}
                    />
                    <LocationSection
                        location={initialValues.location}
                        handleEdit={handleEditLocation}
                        handleAdd={handleAddLocation}
                    />
                </div>
            )}
        </div>
    );
};

export default TaskPreview;
