/* eslint-disable react/display-name */
import React from 'react';

import {
    EmployeeDialog,
    EmployeeRFIDDialog,
    VehicleRFIDDialog,
    VehicleDialog,
    SendRFIDCardsDialog,
    RFIDCardDialog,
    FuelTankDialog,
} from './components';

import About from './components/ApplicationAboutDialog/ApplicationAboutDialog';
import UserProfileDialog from './components/UserProfileDialog/UserProfileDialog';
import BookmarksListDialog from '../../pages/discovery/components/BookmarksList/BookmarkListDialog';

import {
    ABOUT,
    EMPLOYEE,
    EMPLOYEE_RFID,
    REGISTER_VEHICLE,
    REGISTER_VEHICLE_RFID,
    RFID_CARD,
    FUEL_TANK,
    SEND_RFID_CARDS,
    USER_PROFILE,
    BOOKMARKS_LIST,
    LOGIN_MIGRATION,
    GRID_VIEW,
} from '../../constants/dictionaries/GlobalDialogTypes';
import LoginMigrationDialog from './components/LoginMigrationDialog';
import GridViewDialog from './components/GridViewDialog';

export default {
    [EMPLOYEE]: (data: any) => <EmployeeDialog employee={data.employee} />,
    [EMPLOYEE_RFID]: (data: any) => (
        <EmployeeRFIDDialog employee={data.employee} />
    ),
    [REGISTER_VEHICLE]: (data: any) => <VehicleDialog vehicle={data.vehicle} />,
    [REGISTER_VEHICLE_RFID]: (data: any) => (
        <VehicleRFIDDialog vehicle={data.vehicle} />
    ),
    [RFID_CARD]: (data: any) => <RFIDCardDialog rfidCard={data.rfidCard} />,
    [FUEL_TANK]: (data: any) => <FuelTankDialog tank={data.fuelTank} />,
    [SEND_RFID_CARDS]: (data: any) => (
        <SendRFIDCardsDialog fuelTank={data.fuelTank} />
    ),
    [ABOUT]: () => <About />,
    [USER_PROFILE]: () => <UserProfileDialog />,
    [BOOKMARKS_LIST]: () => <BookmarksListDialog />,
    [LOGIN_MIGRATION]: (data: { redirectAfterLogin?: () => void }) => (
        <LoginMigrationDialog redirectAfterLogin={data?.redirectAfterLogin} />
    ),
    [GRID_VIEW]: (data: { id?: string; sourceSet: string }) => {
        return <GridViewDialog viewId={data.id} sourceSet={data.sourceSet} />;
    },
};
