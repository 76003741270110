import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import PlusIcon from '@material-ui/icons/Add';
import ListIcon from '@material-ui/icons/List';
import { FieldProps } from 'formik';
import React from 'react';
import { compose } from 'redux';
import { Themable, TThemableProps } from './Themable.hoc';

interface IOwnProps {
    onAdd?: () => void;
    onSelect?: () => void;
}

type TProps = IOwnProps & TThemableProps & FieldProps;

const GridSearchSelectWrapper: React.FunctionComponent<TProps> = ({
    field,
    form,
    onSelect,
    onAdd,
    classes,
    ...props
}) => (
    <div className={classes.container}>
        <TextField
            margin="dense"
            className={classes.input}
            {...field}
            {...props}
        />
        {onSelect && (
            <IconButton onClick={onSelect} color="primary">
                <ListIcon />
            </IconButton>
        )}
        {onAdd && (
            <IconButton onClick={onAdd} color="primary">
                <PlusIcon />
            </IconButton>
        )}
    </div>
);

export default compose(Themable)(GridSearchSelectWrapper);
