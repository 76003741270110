import { combineReducers } from 'redux';

import clientsAndLocations from './clientsAndLocations';
import bottomGrid from './bottomGrid';
import tasks from './bottomGrid/tasks';
import events from './bottomGrid/events';
import contractItems from './bottomGrid/contractItems';

export default combineReducers({
    clientsAndLocations,
    bottomGrid,
    tasks,
    events,
    contractItems,
});
