import { TRootState } from 'src/app/store';
import { makeReduxDuck } from 'teedux';
import {
    FUEL_TANK,
    EMPLOYEE,
    EMPLOYEE_RFID,
    REGISTER_VEHICLE,
    REGISTER_VEHICLE_RFID,
    RFID_CARD,
    SEND_RFID_CARDS,
    ABOUT,
    USER_PROFILE,
    BOOKMARKS_LIST,
    LOGIN_MIGRATION,
    GRID_VIEW,
} from '../../../constants/dictionaries/GlobalDialogTypes';
import { IFuelTank } from '../../../components/registers/FuelTanks/types';

type TDialogType =
    | 'FUEL_TANK'
    | 'EMPLOYEE'
    | 'EMPLOYEE_RFID'
    | 'REGISTER_VEHICLE'
    | 'REGISTER_VEHICLE_RFID'
    | 'RFID_CARD'
    | 'SEND_RFID_CARDS'
    | 'ABOUT'
    | 'USER_PROFILE'
    | 'BOOKMARKS_LIST'
    | undefined;

interface IState {
    dialogData: undefined;
    dialogType: TDialogType;
}

export const initialState: IState = {
    dialogData: undefined,
    dialogType: undefined,
};

const duck = makeReduxDuck('ui/groups', initialState);

export const setDialog = duck.defineAction<{
    dialogData: any;
    dialogType: any;
}>('SET_DIALOG', (_, { dialogData, dialogType }) => ({
    dialogData,
    dialogType,
}));

export const showFuelTankDialog = (dialogData: { fuelTank: IFuelTank }) =>
    setDialog({ dialogData, dialogType: FUEL_TANK });

export const showEmployeeDialog = (dialogData: any) =>
    setDialog({ dialogData, dialogType: EMPLOYEE });

export const showEmployeeRFIDDialog = (dialogData: any) =>
    setDialog({ dialogData, dialogType: EMPLOYEE_RFID });

export const showVehicleDialog = (dialogData: any) =>
    setDialog({ dialogData, dialogType: REGISTER_VEHICLE });

export const showVehicleRFIDDialog = (dialogData: any) =>
    setDialog({ dialogData, dialogType: REGISTER_VEHICLE_RFID });

export const showRFIDCardDialog = (dialogData: any) =>
    setDialog({ dialogData, dialogType: RFID_CARD });

export const showSendRFIDCardsDialog = (dialogData: any) =>
    setDialog({ dialogData, dialogType: SEND_RFID_CARDS });

export const showAboutDialog = (dialogData: any) =>
    setDialog({ dialogData, dialogType: ABOUT });

export const showUserProfileDialog = (dialogData: any) =>
    setDialog({ dialogData, dialogType: USER_PROFILE });

export const showBookmarskListDialog = (dialogData: any) =>
    setDialog({ dialogData, dialogType: BOOKMARKS_LIST });

export const showLoginMigrationDialog = (dialogData: any) =>
    setDialog({ dialogData, dialogType: LOGIN_MIGRATION });

export const showGridViewDialog = (dialogData: {
    id?: string;
    sourceSet: string;
}) => setDialog({ dialogData, dialogType: GRID_VIEW });

export const hideGlobalDialog = () =>
    setDialog({ dialogData: undefined, dialogType: undefined });

export const getGlobalDialogData = (state: TRootState) =>
    state.ui.globalDialog.dialogData;
export const getGlobalDialogType = (state: TRootState) =>
    state.ui.globalDialog.dialogType;
export default duck.getReducer();
