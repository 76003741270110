import { merge } from 'lodash';

import { IReport, IReportEntity, ITrail } from 'src/app/state/types';
import apiClient from '../../common/apiClient';
import {
    IUrl,
    makeGeoGetRequestSettings,
    makeGetRequestSettings,
} from '../../common/utils';
import { simulateProperDataForPresentation, makeSourceSetId } from './utils';
import { getParamsFromStrategies } from '../../../helpers/getParamsFromStrategies';
import { INormalizedData } from 'src/app/state/app/collections/dataTypes';

export type TAbnomalObject = object & { id: string } & {
    monitoredId?: string;
} & {
    data?: object;
    level?: number;
} & {
    type?: string;
    reports?: object[];
    _meta?: object;
};
type TAbnomalCollection = TAbnomalObject[];
export type INormalizeSingleEntity = (data: TAbnomalObject) => object;
export type INormalizeCollection = (data: TAbnomalCollection) => object;
export type IConvertSingleEntity = (data: any[]) => TAbnomalObject;

export const makeDataStatusFetch =
    (makeUrlCallback: (...args: any[]) => IUrl) =>
    (...args: any[]): Promise<number> => {
        return apiClient
            .sendWithoutErrorsHandling(
                makeGetRequestSettings(makeUrlCallback(...args))
            )
            .then((response) => {
                return response.status;
            });
    };

export const makeFetch =
    <T>(
        makeUrlCallback: (...args: any[]) => IUrl,
        normalize: INormalizeCollection | INormalizeSingleEntity
    ) =>
    (...args: any[]): Promise<object | T> =>
        apiClient
            .sendWithoutErrorsHandling(
                makeGetRequestSettings(makeUrlCallback(...args))
            )
            .then(normalize)
            .then((data) => {
                return data;
            });

export const makeSourceSetElementFetch =
    <T>(
        makeUrlCallback: (...args: any[]) => IUrl,
        normalize: INormalizeCollection | INormalizeSingleEntity
    ) =>
    (...args: any[]): Promise<object | T> =>
        apiClient
            .sendWithoutErrorsHandling(
                makeGetRequestSettings(makeUrlCallback(...args))
            )
            .then((data) => {
                const webxMeta = { ...args[0]?._webx };

                if (args[0]?._webx?.paramsStrategy) {
                    const paramsFromStrategy = {
                        onClick: {
                            params: getParamsFromStrategies(
                                args[0]?._webx?.paramsStrategy,
                                data._meta
                            ),
                        },
                    };
                    webxMeta?.menu?.forEach((menuItem: IReportEntity) =>
                        merge(menuItem.actions, paramsFromStrategy)
                    );
                }
                const mergedMeta = merge(data._meta, webxMeta);
                data._meta = mergedMeta;
                return data;
            })
            .then(normalize);

export const makeLocalFetch =
    (convert: IConvertSingleEntity, normalize: INormalizeSingleEntity) =>
    (...args: any[]): Promise<object> =>
        new Promise<TAbnomalObject>((resolve) => resolve(convert(args)))
            .then(normalize)
            .then((data) => {
                return data;
            });

export const makeCustomSourceSetFetch =
    <T>(
        makeUrlCallback: (...args: any[]) => IUrl,
        normalize?: INormalizeCollection | INormalizeSingleEntity
    ) =>
    (...args: any[]): Promise<T> => {
        const url = makeUrlCallback(...args);
        return apiClient.send(makeGetRequestSettings(url)).then((data) => {
            if (args[0]?._webxMeta) {
                const mergedMeta = merge(data._meta, args[0]._webxMeta);
                data._meta = mergedMeta;
            }

            data.definitionId = data.id;
            data.id = makeSourceSetId(args[0]?.params) || url.toString();

            return normalize ? normalize(data) : data;
        });
    };

export const makeManagedSourceSetFetch =
    <T>(
        makeUrlCallback: (...args: any[]) => IUrl,
        normalize: INormalizeCollection | INormalizeSingleEntity
    ) =>
    (...args: any[]): Promise<object | T> =>
        apiClient
            .sendWithoutErrorsHandling(
                makeGetRequestSettings(makeUrlCallback(...args))
            )
            .then((data) => {
                data.definitionId = data.id;
                data.entities.forEach((entity: { id: any }) => {
                    entity.id = entity.id.toString();
                });
                return data;
            })
            .then(normalize);

export const makeFetchReportSet =
    <T>(
        makeUrlCallback: (...args: any[]) => IUrl,
        normalize: INormalizeSingleEntity
    ) =>
    (monitoredId: number, from: string, to: string): Promise<object | T> =>
        apiClient
            .sendWithoutErrorsHandling(
                makeGetRequestSettings(makeUrlCallback(monitoredId, from, to))
            )
            .then((data: any) =>
                normalize(
                    simulateProperDataForPresentation(
                        data,
                        monitoredId,
                        from,
                        to
                    )
                )
            );

export const makeFetchReport =
    <T>(
        makeUrlCallback: (...args: any[]) => IUrl,
        normalize: (report: IReport) => object
    ) =>
    (...args: any[]): Promise<object | T> =>
        apiClient
            .sendWithoutErrorsHandling(
                makeGetRequestSettings(makeUrlCallback(...args))
            )
            .then((data) => {
                return normalize(data);
            });

export const makeTrailFetch =
    <T>(makeUrlCallback: (...args: any[]) => IUrl, normalize: any) =>
    (...args: any[]): Promise<INormalizedData<ITrail>> =>
        apiClient
            .sendWithRetry(makeGeoGetRequestSettings(makeUrlCallback(...args)))
            .then(
                (data: {
                    features: Array<{
                        properties: { objectId: number };
                    }>;
                }) => {
                    const monitoredId = args[0];
                    const level = args[args.length - 1];
                    return normalize({
                        id: monitoredId.toString(),
                        data,
                        level,
                    });
                }
            );
