import { useSelector } from 'react-redux';
import {
    getContractItem,
    getContractItemsAsSourceSet,
    getContractItemsLoading,
    getContractItemssSourceSetModel,
    getSelectedContractItemId,
    getContractItemsPaneLoading,
    getOpenSectionContracts,
    getOpenSectionContract,
    getOpenSectionContractAttachments,
    getSelectedContractItemCycleId,
} from '.';

export const useContractItems = () => useSelector(getContractItemsAsSourceSet);
export const useContractItemsLoading = () =>
    useSelector(getContractItemsLoading);
export const useContractItemsSourceSetModel = () =>
    useSelector(getContractItemssSourceSetModel);
export const useContractItem = () => useSelector(getContractItem);
export const useContractItemId = () => useSelector(getSelectedContractItemId);
export const useContractItemCycleId = () =>
    useSelector(getSelectedContractItemCycleId);

export const useContractsItemsPaneLoading = () =>
    useSelector(getContractItemsPaneLoading);
export const useOpenSectionContracts = () =>
    useSelector(getOpenSectionContracts);
export const useOpenSectionContract = () => useSelector(getOpenSectionContract);
export const useOpenSectionContractAttachments = () =>
    useSelector(getOpenSectionContractAttachments);
