import { makeReduxDuck } from 'teedux';

import {
    restGetAlertDefinitions,
    restGetEventTypes,
    restGetLayers,
    restGetProviders,
} from '../../../services/alerts';
import { restGetParamsDefinitions } from '../../../services/calibrations';
import { makeRequest } from '../sync';

import { TRootState } from '../../../store';

import { IEventType } from '../../ui/forms';
import { IParamDefinitionItem } from '../calibrations';

const alertsStorePath = `app/alerts`;

export interface IAlertProvider {
    id: string;
    name: string;
}
export interface IAlertDefinitionItem {
    id: number;
    name: string;
}

export interface IRawLayer {
    id: string;
    label: string;
    features: {
        label: string;
    }[];
}

export interface ILayerFeature {
    id?: string;
    name: string;
}

export interface ILayer {
    id: string;
    name: string;
    features: ILayerFeature[];
}

interface IState {
    alertDefinitions: {
        [key: string]: IAlertDefinitionItem[];
    };
    paramsDefinitions: IParamDefinitionItem[];
    chosenParams: any;
    eventTypes: IEventType[];
    layers: ILayer[];
    features: ILayerFeature[];
    providers: IAlertProvider[];
    paramsLoading: boolean;
}

const initialState: IState = {
    alertDefinitions: {},
    chosenParams: {},
    paramsDefinitions: [],
    eventTypes: [],
    layers: [],
    features: [{ id: 'All', name: 'All' }],
    providers: [],
    paramsLoading: false,
};

export const conditionTypes = {
    and: 1,
    or: 2,
    param: 3,
    event: 4,
    layer: 5,
};

const duck = makeReduxDuck(alertsStorePath, initialState);

export const setAlertDefinitionsAction = duck.defineAction<{
    data: IAlertDefinitionItem[];
    type: string;
}>('SET_ALERT_DEFINITIONS', (state, { data, type }) => ({
    alertDefinitions: {
        ...state.alertDefinitions,
        [type]: data,
    },
}));

export const setChosenParamsAction = duck.defineAction<{
    data: IParamDefinitionItem;
}>('SET_CHOSEN_PARAMS', (state, { data }) => ({
    chosenParams: {
        ...state.chosenParams,
        [data.name]: data,
    },
}));

export const setParamsDefinitionsAction = duck.defineAction<{
    data: IParamDefinitionItem[];
}>('SET_PARAM_DEFINITIONS', (_, { data }) => ({
    paramsDefinitions: data,
}));

export const setParamsLoading = duck.defineAction<{
    loading: boolean;
}>('SET_PARAM_LOADING_STATE', (_, { loading }) => ({
    paramsLoading: loading,
}));

export const setEventTypesAction = duck.defineAction<{
    data: IEventType[];
}>('SET_EVENT_TYPES', (_, { data }) => ({
    eventTypes: data,
}));

export const setLayersAction = duck.defineAction<{
    data: ILayer[];
}>('SET_LAYERS', (_, { data }) => ({
    layers: data,
}));

export const setAlertProviders = duck.defineAction<{
    data: IAlertProvider[];
}>('SET_PROVIDERS', (_, { data }) => ({
    providers: data,
}));

export default duck.getReducer();

export const fetchAlertDefinitions = (
    type: string,
    afterSuccess?: (data: IAlertDefinitionItem[]) => void
) =>
    makeRequest(
        `get:${alertsStorePath}/definitions`,
        () => restGetAlertDefinitions(type),
        (dispatch, data) => {
            dispatch(setAlertDefinitionsAction({ data, type }));
            if (afterSuccess) {
                afterSuccess(data);
            }
        },
        (dispatch, error) => undefined
    );

export const fetchAlertProviders = (
    afterSuccess?: (data: IAlertProvider[]) => void
) =>
    makeRequest(
        `get:${alertsStorePath}/definitions`,
        () => restGetProviders(),
        (dispatch, data) => {
            dispatch(setAlertProviders({ data }));
            if (afterSuccess) {
                afterSuccess(data);
            }
        },
        (dispatch, error) => undefined
    );

const alertParamsTypes = 'STRING,INTEGER,INTEGER_64,FLOAT';

export const fetchParamsDefinitions = (
    objectId: string,
    afterSuccess?: (data: IParamDefinitionItem[]) => void,
    afterFail?: (error: any) => void
) =>
    makeRequest(
        `get:${alertsStorePath}/paramsDefinitions`,
        () =>
            restGetParamsDefinitions({
                objectId,
                onlyVisible: true,
                types: alertParamsTypes,
            }),
        (dispatch, data) => {
            dispatch(setParamsDefinitionsAction({ data }));
            dispatch(setParamsLoading({ loading: false }));

            if (afterSuccess) {
                afterSuccess(data);
            }
        },
        (dispatch, error) => {
            dispatch(setParamsDefinitionsAction({ data: [] }));
            dispatch(setParamsLoading({ loading: false }));

            if (afterFail) {
                afterFail(error);
            }
        }
    );

export const fetchEventTypes = (
    forAlerts: boolean,
    afterSuccess?: (data: IEventType[]) => void,
    afterFail?: (error: any) => void
) =>
    makeRequest(
        `get:${alertsStorePath}/events`,
        () => restGetEventTypes(forAlerts),
        (dispatch, data) => {
            dispatch(setEventTypesAction({ data }));
            if (afterSuccess) {
                afterSuccess(data);
            }
        },
        (dispatch, error) => {
            if (afterFail) {
                afterFail(error);
            }
        }
    );

const prepareLayersData = (data: IRawLayer[]): ILayer[] => {
    return data.map((layer) => {
        return {
            id: layer.id,
            name: layer.label,
            features: layer.features.map((feature) => {
                return {
                    name: feature.label,
                };
            }),
        };
    });
};

export const fetchLayers = (
    afterSuccess?: (data: ILayer[]) => void,
    afterFail?: (error: any) => void
) =>
    makeRequest(
        `get:${alertsStorePath}/layers`,
        () => restGetLayers(),
        (dispatch, data) => {
            const preparedData = prepareLayersData(data);
            dispatch(setLayersAction({ data: preparedData }));
            if (afterSuccess) {
                afterSuccess(preparedData);
            }
        },
        (dispatch, error) => {
            if (afterFail) {
                afterFail(error);
            }
        }
    );

export const setChosenParams = (data: IParamDefinitionItem) =>
    setChosenParamsAction({ data });

export const getChosenParams = (state: TRootState) => {
    return state.app.alerts.chosenParams;
};

export const getAlertDefinitions = (state: TRootState) => {
    return state.app.alerts.alertDefinitions || [];
};

export const getParamsDefinitions = (state: TRootState) => {
    return state.app.alerts.paramsDefinitions;
};

export const getParamsLoading = (state: TRootState) => {
    return state.app.alerts.paramsLoading;
};

export const getEventTypes = (state: TRootState) => {
    return state.app.alerts.eventTypes;
};

export const getProviders = (state: TRootState) => {
    return state.app.alerts.providers;
};
export const getLayers = (state: TRootState) => {
    return state.app.alerts.layers;
};

export const getObjects = (state: TRootState) => {
    return state.app.alerts.features;
};
