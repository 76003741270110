import React from 'react';

import { Hidden, useMediaQuery } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import DeleteIcon from '@material-ui/icons/Delete';
import DetailsIcon from '@material-ui/icons/Description';
import { default as EditIcon } from '@material-ui/icons/Edit';
import { default as PlusIcon } from '@material-ui/icons/Add';

import { useStyles } from './Themable.hooks';

import {
    IActionDataParams,
    IBinaryValue,
    IReportActions,
    IReportEntity,
} from '../../../../../../state/types';
import { ISourceSetAction } from '../../../../../../state/ui/discovery/types';

import TranslationHelper from '../../../../../../helpers/TranslationHelper';

import valueFormatters from '../../../_utils/valueFormatters';

import ShowImage from './components/ShowImage';
import {
    DownloadAction,
    PreviewAction,
    SourceSetAction,
    ItemAction,
    ExternalLinkAction,
    DeleteItemAction,
    InternalLinkAction,
} from '../../../../../../components/ButtonActions/ButtonActions';
import { DESKTOP } from '../../../../../../constants/dictionaries/ScreenSizeConst';
import { usePreviewAction } from '../../../../../../pages/discovery/selectors/index.hooks';

interface IOwnProps {
    entity: IReportEntity;
    reportActionTypes?: IReportActions;
    handleReportAction: (
        type: 'add' | 'edit' | 'preview',
        params?: IActionDataParams
    ) => void;
    handleEntityAction: (
        type: 'add' | 'edit' | 'preview',
        params: IActionDataParams
    ) => void;
    handleDelete?: (id: string, message?: string) => void;
    handleOpenSourceSetClick?: (sourceSetAction?: ISourceSetAction) => void;
    reportId: string;
}

const formatEntityValueToString = (entity: IReportEntity): string => {
    return entity.value?.toString() || '';
};

const ReportListItem = ({
    entity,
    handleReportAction,
    handleDelete,
    handleOpenSourceSetClick,
    handleEntityAction,
    reportActionTypes,
    reportId,
}: IOwnProps) => {
    const classes = useStyles();

    const editAction = entity.actions?.edit;
    const deleteAction = entity.actions?.delete;
    const previewAction = entity.actions?.preview;
    const addAction = entity.actions?.add;
    const downloadAction = entity.actions?.download;
    const externalLinkAction = entity.actions?.externalLink;
    const internalLinkAction = entity.actions?.internalLink;
    const sourceSetAction = entity.actions?.sourceSet;
    const entityName =
        typeof entity.value === 'object' ? entity.value.name : '';
    const panePreviewAction = usePreviewAction();
    const linkEntity =
        entity.value.toString().startsWith('http') &&
        entity.type !== 'externalLink';

    const isDesktop = !!useMediaQuery(DESKTOP);

    const editHandler =
        editAction &&
        ((reportActionTypes?.edit && handleReportAction) || handleEntityAction);
    const previewHandler =
        previewAction &&
        ((reportActionTypes?.preview && handleReportAction) ||
            handleEntityAction);

    const deleteHandler =
        deleteAction && reportActionTypes?.delete && handleDelete;

    const addHandler =
        (addAction && handleEntityAction) ||
        (reportActionTypes?.add && handleReportAction);

    const deleteMeta = deleteHandler && reportActionTypes?.delete?._meta;
    const handleSourceSetAction = () => {
        handleOpenSourceSetClick && handleOpenSourceSetClick(sourceSetAction);
    };

    const renderEntityValue = () => {
        // TODO: refactor to redner any action and only use custom components for some
        const basicEntity =
            (Array.isArray(entity.value) || typeof entity.value !== 'object') &&
            !externalLinkAction &&
            !linkEntity;
        return (
            !!entity.label &&
            (basicEntity ? (
                <span className={classes.itemValue}>
                    {(
                        valueFormatters[entity.type] ||
                        formatEntityValueToString
                    )(entity)}
                    {sourceSetAction &&
                        !panePreviewAction?._webx?.changeSourceSetDisabled &&
                        handleOpenSourceSetClick && (
                            <Hidden smDown={true}>
                                <SourceSetAction
                                    classes={classes}
                                    handleOnClick={handleSourceSetAction}
                                />
                            </Hidden>
                        )}
                </span>
            ) : (
                <span className={classes.attachment}>
                    {downloadAction && (
                        <DownloadAction
                            classes={classes}
                            api={downloadAction.api}
                            name={entityName}
                            isMovie={
                                (entity.value as { isMovie: boolean }).isMovie
                            }
                        />
                    )}
                    {previewAction && (
                        <Hidden smDown={true}>
                            <PreviewAction
                                classes={classes}
                                api={previewAction.api}
                            />
                        </Hidden>
                    )}
                    {externalLinkAction && (
                        <ExternalLinkAction
                            classes={classes}
                            url={externalLinkAction.url}
                        />
                    )}

                    {sourceSetAction &&
                        !panePreviewAction?._webx?.changeSourceSetDisabled &&
                        handleOpenSourceSetClick && (
                            <Hidden smDown={true}>
                                <SourceSetAction
                                    classes={classes}
                                    handleOnClick={handleSourceSetAction}
                                />
                            </Hidden>
                        )}
                    {linkEntity && (
                        <ExternalLinkAction
                            classes={classes}
                            url={entity.value.toString()}
                            withCopy
                        />
                    )}
                </span>
            ))
        );
    };
    return (
        <ListItem className={classes.listItem}>
            {previewAction && downloadAction ? (
                <ShowImage
                    classes={classes}
                    apiPreview={previewAction.api}
                    apiDownload={downloadAction.api}
                    name={`${TranslationHelper.translate(
                        entity.label as string,
                        'mp'
                    )} (${entity.label})`}
                    download={
                        (entity.value as IBinaryValue)?.name ||
                        (entity.value as string)
                    }
                    valid={!!previewAction.api}
                    type={entity.type}
                    paramName={entity.id}
                    reportId={reportId}
                />
            ) : (
                <>
                    <div className={classes.itemLabel}>
                        {entity.translatedLabel || entity.label || entity.value}
                    </div>
                    <span className={classes.secondaryAction}>
                        {renderEntityValue()}
                        <>
                            {isDesktop && (
                                <>
                                    {internalLinkAction && (
                                        <InternalLinkAction
                                            classes={classes}
                                            url={internalLinkAction.url}
                                            openInNewTab={
                                                internalLinkAction.openInNewTab
                                            }
                                            icon={internalLinkAction._meta.icon}
                                        />
                                    )}
                                    {previewAction && previewHandler && (
                                        <ItemAction
                                            classes={classes}
                                            params={previewAction.params}
                                            handler={previewHandler}
                                            action="preview"
                                            label="Details"
                                            id={entity.id}
                                        >
                                            <DetailsIcon
                                                className={
                                                    classes.iconButtonIcon
                                                }
                                            />
                                        </ItemAction>
                                    )}
                                    {editAction && editHandler && (
                                        <ItemAction
                                            classes={classes}
                                            params={editAction.params}
                                            handler={editHandler}
                                            action="edit"
                                            label="Edit"
                                            id={entity.id}
                                        >
                                            <EditIcon
                                                className={
                                                    classes.iconButtonIcon
                                                }
                                            />
                                        </ItemAction>
                                    )}
                                    {addAction && addHandler && (
                                        <ItemAction
                                            classes={classes}
                                            params={addAction.params}
                                            handler={addHandler}
                                            action="add"
                                            label="Add"
                                            id={entity.id}
                                        >
                                            <PlusIcon
                                                className={
                                                    classes.iconButtonIcon
                                                }
                                            />
                                        </ItemAction>
                                    )}
                                    {deleteAction && deleteHandler && (
                                        <DeleteItemAction
                                            classes={classes}
                                            params={deleteAction.params}
                                            handler={deleteHandler}
                                            deleteMeta={deleteMeta}
                                        >
                                            <DeleteIcon
                                                className={
                                                    classes.iconButtonIcon
                                                }
                                            />
                                        </DeleteItemAction>
                                    )}
                                </>
                            )}
                        </>
                    </span>
                </>
            )}
        </ListItem>
    );
};

export default ReportListItem;
