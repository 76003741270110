import URI from 'urijs';
import AppConfig from '../../constants/AppConfig';
import UrlConst from '../../constants/UrlConst';
import apiClient from '../common/apiClient';
import {
    makeDeleteRequestSettings,
    makeGetRequestSettings,
    makePatchRequestSettings,
    makePostRequestSettings,
    makePutRequestSettings,
    makeUrl,
} from '../common/utils';

export const makeUsersUrl = (suffix: string, params = {}) => {
    const url = new URI(
        AppConfig.instance.getUrl(UrlConst.USERS_ENDPOINT_URL) + suffix
    );
    return makeUrl(url, params);
};

export interface IRestPostPasswordReset {
    login?: string;
    email?: string;
    tenant: string;
}

export interface IRight {
    type: string;
    relationId: string;
    registerId?: number;
    registerType?: string;
}

export interface IRestPatchRightsAssignments {
    mode: string;
    added: IRight[];
    removed: IRight[];
}

export const restGetRights = (id: number): Promise<IRight[]> =>
    apiClient.sendWithoutErrorsHandling(
        makeGetRequestSettings(makeUsersUrl(`/${id}/rights`))
    );

export const restPatchRightsAssignments = (
    id: number,
    assignments: IRestPatchRightsAssignments
): Promise<null> =>
    apiClient.sendWithoutErrorsHandling(
        makePatchRequestSettings(makeUsersUrl(`/${id}/rights`), assignments)
    );

export const restPostPasswordReset = (
    password: IRestPostPasswordReset
): Promise<null> =>
    apiClient.sendWithoutErrorsHandling(
        makePostRequestSettings(makeUsersUrl('/password-reset'), password)
    );

export const restPostUserLoginMiration = (login: string): Promise<null> =>
    apiClient.send(
        makePostRequestSettings(makeUsersUrl('/migrations'), { login })
    );

export const restDeleteUserLoginMiration = (): Promise<null> =>
    apiClient.sendWithoutErrorsHandling(
        makeDeleteRequestSettings(makeUsersUrl('/migrations'))
    );
export const restPostResendEmail = (): Promise<null> =>
    apiClient.sendWithoutErrorsHandling(
        makePostRequestSettings(
            makeUsersUrl('/migrations/confirmation/resend'),
            {}
        )
    );

export const restPostMigrationResetPassword = (
    newPassword: string,
    token: string,
    login: string
): Promise<null> =>
    apiClient.sendWithoutErrorsHandling(
        makePostRequestSettings(makeUsersUrl('/migrations/confirmation'), {
            newPassword,
            token,
            login,
        })
    );

export interface IRestPutPassword {
    newPassword: string;
}

export const restPutPasswordReset = (
    password: IRestPutPassword,
    token: string
): Promise<null> =>
    apiClient.sendWithoutErrorsHandling(
        makePutRequestSettings(makeUsersUrl(`/password-reset`), password, {
            'XTrack-Password-Reset-Token': token,
        })
    );
