import React, { ChangeEvent } from 'react';

import { useToolkitDispatch } from '../../../../hooks';

import {
    closeBottomGrid,
    selectBottomGrid,
    TGridTypes,
} from '../../../../state/ui/customerService/bottomGrid';
import { useBottomGrid } from '../../../../state/ui/customerService/bottomGrid/index.hooks';

import SelectField from '../../../../components/SelectField';
import ContractsGrid from './ContractItemsGrid';
import TasksGrid from './TasksGrid/TasksGrid';
import EventsGrid from './EventsGrid/EventsGrid';

export const GRID_TYPES: TGridTypes[] = ['Tasks', 'ContractItems', 'Events'];

const BottomGrid = () => {
    const selectedGrid = useBottomGrid();

    const toolkitDispatch = useToolkitDispatch();

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        handleChangeGrid(e.target.value as TGridTypes);
    };

    const handleChangeGrid = (grid: TGridTypes) => {
        toolkitDispatch(selectBottomGrid(grid));
    };

    const handleClose = () => {
        toolkitDispatch(closeBottomGrid());
    };
    return (
        <>
            {selectedGrid === 'Tasks' && (
                <TasksGrid
                    handleClose={handleClose}
                    titleComponent={
                        <SelectField
                            name="selectGrid"
                            value="Tasks"
                            ids={GRID_TYPES}
                            handleChange={handleChange}
                        />
                    }
                />
            )}
            {selectedGrid === 'ContractItems' && (
                <ContractsGrid
                    handleClose={handleClose}
                    titleComponent={
                        <SelectField
                            name="selectGrid"
                            value="ContractItems"
                            ids={GRID_TYPES}
                            handleChange={handleChange}
                        />
                    }
                />
            )}
            {selectedGrid === 'Events' && (
                <EventsGrid
                    handleClose={handleClose}
                    titleComponent={
                        <SelectField
                            name="selectGrid"
                            value="Events"
                            ids={GRID_TYPES}
                            handleChange={handleChange}
                        />
                    }
                />
            )}
        </>
    );
};
export default BottomGrid;
