import React, { ChangeEvent, useEffect, useState } from 'react';

import { SelectionChangedEvent } from 'ag-grid-community';

import { Divider, LinearProgress, Paper, MenuItem } from '@material-ui/core';

import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';

import {
    useIsRoutesPaneVisible,
    useRoutes,
    useRoutesSourceSetModel,
    useRoutesStatus,
    useSelectedCategory,
    useSelectedDates,
    useSelectedRouteId,
    useSelectedRoutes,
} from '../../../../state/ui/planning/routes/index.hooks';
import { ISourceSetEntity } from '../../../../state/types';
import {
    enterRouteCreateMode,
    enterRoutePreviewMode,
    fetchRoutes,
    resetRouteMode,
    selectActivityCategory,
    selectDates,
    selectRoutes,
} from '../../../../state/ui/planning/routes';

import { useToolkitDispatch } from '../../../../hooks';
import TranslationHelper from '../../../../helpers/TranslationHelper';
import useAsyncThunkWithSnackbar from '../../../../hooks/useAsyncThunkWithSnackbar';

import { useStyles } from '../../Themable.hooks';

import IconButtonWithTooltip from '../../../../components/IconButtonWithTooltip';
import PaneHeader from '../../../../components/PaneHeader';
import SearchField from '../../../../components/common/SearchField/SearchField';
import SourceSetGrid from '../../../../components/SourceSetGrid';
import DateRangeDisplay from '../../../../components/DateRangeDisplay';
import { useActivityCategory } from '../../../../state/app/dictionaries/index.hooks';
import SelectField from '../../../../components/SelectField';
import { usePrivileges } from '../../../../state/auth/index.hooks';
import isEqual from 'lodash.isequal';
import { useSnackbar } from 'notistack';

const CHECKBOX_POSITION = 0;

const RoutesGrid = () => {
    const sourceSetModel = useRoutesSourceSetModel();

    const [filter, setFilter] = useState(sourceSetModel?.quickFilter || '');
    const [refreshGrid, setRefreshGrid] = useState(false);
    const category = useSelectedCategory();
    const dates = useSelectedDates();
    const classes = useStyles();
    const routes = useRoutes();
    const status = useRoutesStatus();
    const selectedRouteId = useSelectedRouteId();
    const selectedRoutes = useSelectedRoutes();
    const privileges = usePrivileges();
    const categories = useActivityCategory();
    const routesPaneVisible = useIsRoutesPaneVisible();

    const toolkitDispatch = useToolkitDispatch();
    const handleAsyncRequest = useAsyncThunkWithSnackbar();

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (category) {
            handleFetchRoutes(dates.from, dates.to, category);
        }
    }, [category]);

    const renderRightCustomControls = () => {
        return (
            <>
                {privileges.addRoute && (
                    <IconButtonWithTooltip
                        title={TranslationHelper.translate('Add route')}
                        onClick={handleCreateRoute}
                    >
                        <AddIcon />
                    </IconButtonWithTooltip>
                )}
            </>
        );
    };

    const handleCreateRoute = () => {
        toolkitDispatch(enterRouteCreateMode());
    };

    const handleCellInteraction = (data: ISourceSetEntity) => {};

    const onSelectionChanged = (e: SelectionChangedEvent) => {
        const selectedNodes = e.api.getSelectedNodes().map((selected) => {
            return {
                id: selected.data.id,
                sourceSetId: selected.data.id,
                name: selected.data.name,
            };
        });
        if (isEqual(selectedNodes, selectedRoutes)) {
            return;
        }
        toolkitDispatch(selectRoutes(selectedNodes));
    };
    const multiSelectProps = {
        rowSelection: 'multiple',
        checkBox: { index: CHECKBOX_POSITION },
        onSelectionChanged,
        disabledClickSelectionRenderers: ['actionsCellRenderer'],
        items: selectedRoutes,
    };

    const handleFetchRoutes = (
        from: string,
        to: string,
        categoryParam?: number
    ) => {
        toolkitDispatch(resetRouteMode());
        toolkitDispatch(selectDates({ from, to }));
        if (!category) {
            return;
        }
        return handleAsyncRequest({
            asyncAction: fetchRoutes({
                from,
                to,
                activityCategoryId: categoryParam ?? category,
            }),
        });
    };

    const handleChangeCategory = (e: ChangeEvent<HTMLInputElement>) => {
        toolkitDispatch(selectActivityCategory(e.target.value));
    };

    const renderOptions = () => {
        return categories?.map((cat) => {
            return (
                <MenuItem key={cat.id} value={cat.id}>
                    {TranslationHelper.translate(cat.name, 'wx')}
                </MenuItem>
            );
        });
    };

    useEffect(() => {
        if (refreshGrid && category) {
            handleFetchRoutes(dates.from, dates.to, category);
            setRefreshGrid(false);
        }
    }, [refreshGrid, category, dates]);
    return (
        <Paper className={classes.gridPane}>
            <div className={classes.wrapper}>
                <PaneHeader
                    title={TranslationHelper.translate('Routes')}
                    renderLeftCustomControls={() => (
                        <>
                            <SelectField
                                name="category"
                                label="Category"
                                value={String(category)}
                                renderOptions={renderOptions}
                                handleChange={handleChangeCategory}
                            />
                            <SearchField
                                value={filter}
                                filterChange={setFilter}
                            />

                            <DateRangeDisplay
                                onDateChange={handleFetchRoutes}
                                sourceSetId={routes?.id}
                                dates={dates}
                                datesFormat={'short'}
                                small
                                calendarIconOnly={!!routesPaneVisible}
                            />
                        </>
                    )}
                    renderRightCustomControls={() =>
                        renderRightCustomControls()
                    }
                />
                {status === 'idle' && (
                    <Box
                        sx={{
                            p: 1,
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {TranslationHelper.translate(
                            'Select category to load routes'
                        )}
                    </Box>
                )}
                {status === 'loading' ? <LinearProgress /> : <Divider />}
                {routes && category && (
                    <SourceSetGrid
                        sourceSet={routes}
                        cellInteractionHandler={handleCellInteraction}
                        quickFilterValue={filter}
                        sourceSetModel={sourceSetModel}
                        selectedSourceSetElementId={String(selectedRouteId)}
                        multiSelectProps={multiSelectProps}
                        actionHandlers={{
                            preview: (params) => {
                                return toolkitDispatch(
                                    enterRoutePreviewMode(params.id)
                                );
                            },
                            ...(privileges.deleteRoute && {
                                delete: () => {
                                    setRefreshGrid(true);
                                    enqueueSnackbar(
                                        TranslationHelper.translate(
                                            'Route was deleted'
                                        ),
                                        { variant: 'success' }
                                    );
                                },
                            }),
                        }}
                    />
                )}
            </div>
        </Paper>
    );
};

export default RoutesGrid;
