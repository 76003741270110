import React from 'react';

import {
    enterClientEditMode,
    enterLocationAddMode,
    enterLocationEditMode,
    resetMode,
} from '../../../../../../../state/ui/customerService/clientsAndLocations';
import {
    useSelectedClient,
    useSelectedLocation,
} from '../../../../../../../state/ui/customerService/clientsAndLocations/index.hooks';

import { useToolkitDispatch } from '../../../../../../../hooks';
import TranslationHelper from '../../../../../../../helpers/TranslationHelper';

import PaneHeader from '../../../../../../../components/PaneHeader';
import { BLANK_FORM_CLIENT, BLANK_FORM_LOCATION } from '../blankForms';
import ClientSection from '../ClientSection';
import LocationSection from '../LocationSection/LocationSection';

const ClientAndLocationPreview = () => {
    const client = useSelectedClient();
    const location = useSelectedLocation();
    const toolkitDispatch = useToolkitDispatch();
    const initialValues = {
        client: client ? { ...BLANK_FORM_CLIENT, ...client } : null,
        location: location
            ? {
                  ...BLANK_FORM_LOCATION,

                  ...location,
                  address: {
                      ...BLANK_FORM_LOCATION.address,
                      ...location.address,
                  },
                  coordinate: {
                      ...BLANK_FORM_LOCATION.coordinate,
                      ...location.coordinate,
                  },
              }
            : null,
    };

    const handleClose = () => {
        toolkitDispatch(resetMode());
    };

    const handleEditClient = () => {
        toolkitDispatch(enterClientEditMode());
    };

    const handleEditLocation = () => {
        toolkitDispatch(enterLocationEditMode());
    };

    const handleAddLocation = () => {
        toolkitDispatch(enterLocationAddMode());
    };

    return (
        <>
            <PaneHeader
                onCloseClick={handleClose}
                title={TranslationHelper.translate('Details')}
            />

            <div>
                <ClientSection
                    client={initialValues.client}
                    handleEditClient={handleEditClient}
                    showList
                />
                <LocationSection
                    location={initialValues.location}
                    handleAdd={handleAddLocation}
                    handleEdit={handleEditLocation}
                    showList
                />
            </div>
        </>
    );
};

export default ClientAndLocationPreview;
