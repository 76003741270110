import URI from 'urijs';
import AppConfig from '../../constants/AppConfig';
import UrlConst from '../../constants/UrlConst';
import apiClient from '../common/apiClient';
import {
    makeGetRequestSettings,
    makePostRequestSettings,
    makePutRequestSettings,
    makeUrl,
} from '../common/utils';
import {
    IPatchRoute,
    IPostRoute,
    IRoute,
} from '../../state/ui/planning/routes';
import { ITask } from '../../state/ui/planning/tasks';

export interface IFetchRoutes {
    from?: string;
    to?: string;
    activityCategoryId: number;
}

export const makeRoutesUrl = (suffix: string, params = {}) => {
    const url = new URI(
        AppConfig.instance.getUrl(UrlConst.ROUTES_MANAGEMENT_URL) + suffix
    );
    return makeUrl(url, params);
};
export const restGetRoutes = (queryParams?: IFetchRoutes): Promise<IRoute[]> =>
    apiClient.send(
        makeGetRequestSettings(makeRoutesUrl('/routes', queryParams))
    );

export const restGetRoutesManagementTasks = (
    routeId?: string
): Promise<ITask[]> =>
    apiClient.send(
        makeGetRequestSettings(makeRoutesUrl(`/routes/${routeId}/tasks`))
    );

export const restGetRoutesManagementUnassignedTasks = (
    params: IFetchRoutes
): Promise<ITask[]> =>
    apiClient.send(makeGetRequestSettings(makeRoutesUrl('/tasks', params)));

export const restGetRoute = (id: number): Promise<IRoute> =>
    apiClient.send(makeGetRequestSettings(makeRoutesUrl(`/routes/${id}`)));

export const restPostRoute = (route: IPostRoute): Promise<{ id: string }> =>
    apiClient.send(makePostRequestSettings(makeRoutesUrl(`/routes`), route));

export const restPutRoute = (
    id: number,
    route: IPatchRoute
): Promise<{ id: string }> =>
    apiClient.send(
        makePutRequestSettings(makeRoutesUrl(`/routes/${id}`), route)
    );
