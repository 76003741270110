import { useToolkitDispatch } from '../hooks';

import { useSnackbar } from 'notistack';
import { AsyncThunkAction } from '@reduxjs/toolkit';
import { errorMessageHandler } from '../helpers/errorMessageHandler';

interface IAsyncActionResult {
    callback?: () => void;
    message?: string;
}
interface IAsyncThunkWrapper {
    asyncAction: AsyncThunkAction<any, any, any>;
    onSuccess?: IAsyncActionResult;
    onError?: IAsyncActionResult;
}
const useAsyncThunkWithSnackbar = () => {
    const toolkitDispatch = useToolkitDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const handleAsyncRequest = async ({
        asyncAction,
        onSuccess,
        onError,
    }: IAsyncThunkWrapper) => {
        await toolkitDispatch(asyncAction)
            .unwrap()
            .then(() => {
                onSuccess?.callback && onSuccess.callback();
                onSuccess?.message &&
                    enqueueSnackbar(onSuccess.message, { variant: 'success' });
            })
            .catch((error) => {
                onError?.callback && onError?.callback();
                const message = errorMessageHandler(error.status)();
                enqueueSnackbar(message, { variant: 'error' });
            });
    };
    return handleAsyncRequest;
};

export default useAsyncThunkWithSnackbar;
