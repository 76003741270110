import React, { useEffect, useState } from 'react';

import { Divider, LinearProgress, Paper } from '@material-ui/core';
import { default as PlusIcon } from '@material-ui/icons/Add';

import { GridApi, SelectionChangedEvent } from 'ag-grid-community';

import {
    closeBottomGrid,
    selectBottomGrid,
} from '../../../../state/ui/customerService/bottomGrid';
import {
    enterCreateMode,
    selectClientsAndLocations,
    resetMode as resetClientAndLocationMode,
    toggleCollapseLocations,
} from '../../../../state/ui/customerService/clientsAndLocations';
import { useBottomGrid } from '../../../../state/ui/customerService/bottomGrid/index.hooks';
import {
    enterPreviewMode,
    fetchClients,
    fetchLocations,
    selectClient,
    selectGridItemId,
    selectLocation,
} from '../../../../state/ui/customerService/clientsAndLocations';
import {
    useClientsAndLocations,
    useClientsAndLocationsLoading,
    useClientsSourceSetModel,
    useSelectedGridItemId,
    useSelectedClientsAndLocations,
} from '../../../../state/ui/customerService/clientsAndLocations/index.hooks';
import { ISourceSetEntity } from '../../../../state/types';
import { resetMode } from '../../../../state/ui/customerService/bottomGrid';
import { selectContractItem } from '../../../../state/ui/customerService/bottomGrid/contractItems';
import { selectTask } from '../../../../state/ui/customerService/bottomGrid/tasks';
import { usePrivileges } from '../../../../state/auth/index.hooks';

import { useToolkitDispatch } from '../../../../hooks';

import TranslationHelper from '../../../../helpers/TranslationHelper';

import { useStyles } from '../../Themable.hooks';

import PaneHeader from '../../../../components/PaneHeader';
import SourceSetGrid from '../../../../components/SourceSetGrid';
import SearchField from '../../../../components/common/SearchField/SearchField';
import IconButtonWithTooltip from '../../../../components/IconButtonWithTooltip';
import CscExportButtons from '../CscExportButtons/CscExportButtons';
import { useSnackbar } from 'notistack';
import { handleQueryAction } from '../../../../helpers/handleQueryAction';
import { handleGridNodeSelection } from '../../../../helpers/handleGridNodeSelection';

const CHECKBOX_POSITION = 0;

const ClientsAndLocationsGrid = () => {
    const { enqueueSnackbar } = useSnackbar();

    const sourceSetModel = useClientsSourceSetModel();

    const [filter, setFilter] = useState(sourceSetModel?.quickFilter || '');
    const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);

    const loading = useClientsAndLocationsLoading();
    const classes = useStyles();
    const clientsAndLocations = useClientsAndLocations();
    const gridItemId = useSelectedGridItemId();
    const bottomGrid = useBottomGrid();
    const privileges = usePrivileges();
    const selectedClientsAndLocations = useSelectedClientsAndLocations();
    const toolkitDispatch = useToolkitDispatch();

    useEffect(() => {
        toolkitDispatch(fetchClients());
        toolkitDispatch(fetchLocations());
    }, []);

    useEffect(() => {
        if (
            !bottomGrid &&
            selectedClientsAndLocations.some((item) => item.id)
        ) {
            toolkitDispatch(selectBottomGrid('Tasks'));
        }
    }, [selectedClientsAndLocations]);

    useEffect(() => {
        if (gridApi && clientsAndLocations) {
            handleQueryAction('selectLocation', (urlParams) => {
                const location = clientsAndLocations.entities.find(
                    (entity) => entity.locationId === urlParams.locationId
                );
                handleLocationSelect(gridApi, location);
            });
        }
    }, [clientsAndLocations, gridApi]);

    const handleLocationSelect = (
        api: GridApi,
        location: ISourceSetEntity | undefined
    ) => {
        if (location?.locationId) {
            handleGridNodeSelection(
                api,
                location.locationId,
                'locationId',
                locationNotFoundSnackbarError
            );
            handleCellInteraction(location);
            handleToggleCollapseLocations();
        } else {
            locationNotFoundSnackbarError();
        }
    };

    const locationNotFoundSnackbarError = () => {
        enqueueSnackbar(
            TranslationHelper.translate(
                'Location with provided id does not exist'
            ),
            { variant: 'error' }
        );
    };

    const handleCreateClient = () => {
        resetBottomGrid();
        toolkitDispatch(enterCreateMode());
    };

    const resetBottomGrid = () => {
        toolkitDispatch(resetMode());
        toolkitDispatch(selectContractItem(null));
        toolkitDispatch(selectTask(null));
    };
    const resetMultiSelection = () => {
        toolkitDispatch(closeBottomGrid());
        resetBottomGrid();
        toolkitDispatch(resetClientAndLocationMode());
        toolkitDispatch(selectClientsAndLocations([]));
    };
    const renderRightCustomControls = () => {
        return (
            <>
                <CscExportButtons api={gridApi} prefix="clientsAndLocations" />
                <IconButtonWithTooltip
                    title={TranslationHelper.translate('Add client')}
                    onClick={handleCreateClient}
                >
                    <PlusIcon />
                </IconButtonWithTooltip>
            </>
        );
    };
    const onSelectionChanged = (e: SelectionChangedEvent) => {
        const selectedNodes = e.api
            .getSelectedNodes()
            .map((selected) => {
                return {
                    id: selected.data.externalId,
                    name: selected.data.locationName,
                    clientId: selected.data.clientId,
                    clientName: selected.data.name,
                    sourceSetId: selected.data.id,
                    locationId: selected.data.locationId,
                    coordinates: selected.data.coordinate,
                    radius: selected.data.radius,
                };
            })
            .filter(Boolean);
        if (!selectedNodes.length) {
            resetMultiSelection();
            return;
        }
        toolkitDispatch(selectClientsAndLocations(selectedNodes));
    };

    const handleCellInteraction = (data: ISourceSetEntity) => {
        resetBottomGrid();
        toolkitDispatch(enterPreviewMode());
        toolkitDispatch(selectClient(data.clientId));
        toolkitDispatch(
            selectLocation({ externalId: data.externalId, id: data.locationId })
        );
        //without timeout, state of selected nodes in SS grid in function ensureVisible is incorrect, due to change of selectedSourceSetElementId, which cause another rerender
        setTimeout(() => toolkitDispatch(selectGridItemId(data.id)), 200);
    };

    const handleSetApiGrid = (
        gridApi: any,
        gridColumnApi: any | undefined = undefined
    ) => {
        setGridApi(gridApi);
    };

    const handleToggleCollapseLocations = () => {
        toolkitDispatch(toggleCollapseLocations());
    };

    const multiSelectProps = {
        rowSelection: 'multiple',
        checkBox: { index: CHECKBOX_POSITION },
        onSelectionChanged,
        items: selectedClientsAndLocations,
    };
    return (
        <Paper className={classes.gridPane}>
            <div className={classes.wrapper}>
                <PaneHeader
                    title={TranslationHelper.translate('Clients and locations')}
                    renderRightCustomControls={
                        privileges.manageCustomerServiceCentre
                            ? () => renderRightCustomControls()
                            : undefined
                    }
                    renderLeftCustomControls={() => (
                        <>
                            <SearchField
                                value={filter}
                                filterChange={setFilter}
                            />
                        </>
                    )}
                />
                {!clientsAndLocations || loading ? (
                    <LinearProgress />
                ) : (
                    <Divider />
                )}
                {clientsAndLocations && (
                    <SourceSetGrid
                        sourceSet={clientsAndLocations}
                        cellInteractionHandler={handleCellInteraction}
                        setGridApi={handleSetApiGrid}
                        quickFilterValue={filter}
                        sourceSetModel={sourceSetModel}
                        selectedSourceSetElementId={gridItemId}
                        multiSelectProps={multiSelectProps}
                    />
                )}
            </div>
        </Paper>
    );
};

export default ClientsAndLocationsGrid;
