import React from 'react';

import { Paper } from '@material-ui/core';

import { useClientAndLocationPaneVisible } from '../../state/ui/customerService/clientsAndLocations/index.hooks';
import {
    useBottomGrid,
    useBottomGridPaneVisible,
} from '../../state/ui/customerService/bottomGrid/index.hooks';

import TranslationHelper from '../../helpers/TranslationHelper';

import { useStyles } from './Themable.hooks';

import View from '../../components/View';
import BottomGrid from './components/BottomGrid';
import ClientsAndLocationsGrid from './components/ClientsAndLocationsGrid';
import ClientAndLocationPane from './components/ClientServicePane/components/ClientsAndLocations/ClientAndLocationPane';
import TasksPane from './components/ClientServicePane/components/Tasks/TasksPane';
import ContractsPane from './components/ClientServicePane/components/ContractItems/ContractItemsPane';
import EditOverlay from '../discovery/components/EditOverlay';
import MapDialog from './components/MapDialog/MapDialog';
import EventsPane from './components/ClientServicePane/components/EventsPane/EventsPane';

const CustomerServicePage = () => {
    const classes = useStyles();
    const bottomGrid = useBottomGrid();

    const clientAndLocationPaneVisible = useClientAndLocationPaneVisible();
    const bottomGridVisible = useBottomGridPaneVisible();

    const paneVisible = clientAndLocationPaneVisible || bottomGridVisible;

    const gridPaneClass = bottomGrid
        ? classes.visibleBottomGrid
        : classes.hiddenBottomGrid;

    const gridsClass = paneVisible ? classes.paneVisible : classes.hiddenPane;
    const renderPane = () => {
        return bottomGridVisible ? (
            <>
                {bottomGrid === 'Tasks' && bottomGridVisible && <TasksPane />}
                {bottomGrid === 'ContractItems' && bottomGridVisible && (
                    <ContractsPane />
                )}
                {bottomGrid === 'Events' && bottomGridVisible && <EventsPane />}
            </>
        ) : (
            clientAndLocationPaneVisible && <ClientAndLocationPane />
        );
    };
    return (
        <View title={TranslationHelper.translate('Client service')}>
            <div className={classes.pageContent}>
                <div className={gridsClass}>
                    <div className={gridPaneClass}>
                        <ClientsAndLocationsGrid />
                    </div>
                    {bottomGrid && (
                        <div className={classes.bottomGrid}>
                            <BottomGrid />
                        </div>
                    )}
                </div>
                {paneVisible && (
                    <Paper className={classes.paneWrapper}>
                        {renderPane()}
                    </Paper>
                )}
                <MapDialog />
                <EditOverlay />
            </div>
        </View>
    );
};

export default CustomerServicePage;
