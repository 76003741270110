import React from 'react';
import { useStyles } from './Themable.hooks';

import { ColDef, GridOptions } from 'ag-grid-community';

import AppConfig from '../../constants/AppConfig';

import { gridTranslateFunc } from '../SourceSetGrid/_utils';

import AgGridEnterprise from '../SourceSetGrid/components/AgGridEnterprise';

import { TGridProps } from '../SourceSetGrid/components/AgGridEnterprise/types';

const defaultColDef: ColDef = {
    flex: 1,
    resizable: true,
};

const ServerSideRowModelGrid = (props: GridOptions) => {
    const classes = useStyles();

    const enterpriseKey =
        AppConfig &&
        AppConfig.instance.getConfigKey(AppConfig.PROPERTY_GRID)?.key;

    const gridProps: TGridProps = {
        defaultColDef,
        embedFullWidthRows: true,
        suppressScrollOnNewData: true,
        localeTextFunc: gridTranslateFunc,
        suppressContextMenu: true,
        rowModelType: 'serverSide',
        maxConcurrentDatasourceRequests: 2,
        cacheBlockSize: 50,
        stopEditingWhenCellsLoseFocus: true,
        maxBlocksInCache: 10,
        blockLoadDebounceMillis: 100,
        ...props,
        enterpriseKey,
    };

    return (
        <div className={classes.gridContainer}>
            <div className={`ag-theme-balham ${classes.gridWrapper}`}>
                <AgGridEnterprise {...gridProps} />
            </div>
        </div>
    );
};

export default ServerSideRowModelGrid;
