import React, { Component } from 'react';
import { compose } from 'redux';
import TranslationHelper from '../../../helpers/TranslationHelper';
import { ISourceSet, ISourceSetEntity } from '../../../state/types';
import { ISourceSetModel } from '../../../state/ui/discovery/types';
import PhraseFilter from '../../common/PhraseFilter';
import SourceSetGrid from '../../SourceSetGrid';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import LinearProgress from '@material-ui/core/LinearProgress';
import Slide, { SlideProps } from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import DialogTitle from '../DialogTitle';

import { Themable, TThemableProps } from './Themable.hoc';

interface IOwnProps {
    isOpen: boolean;
    close: () => void;
    confirm: (data: ISourceSetEntity) => void;
    title: string;
    sourceSet?: ISourceSet;
    fetching?: boolean;
    fetchDataHandler: (filter: string) => void;
    onResetData: () => void;
}
type TProps = IOwnProps & TThemableProps;

const TransitionComponent = React.forwardRef<unknown, TransitionProps>(
    function Transition(props, ref) {
        if (props) {
            return (
                <Slide direction="up" ref={ref} {...(props as SlideProps)} />
            );
        } else {
            return <Slide direction="up" ref={ref} />;
        }
    }
);
interface IState {
    filter: string;
    sourceSetModel?: ISourceSetModel;
    justOpened?: boolean;
}

class GridSearchDialog extends Component<TProps, IState> {
    public static getDerivedStateFromProps(
        nextProps: TProps,
        currentState: IState
    ) {
        let nextState = currentState;

        if (currentState.filter !== '' && !nextProps.isOpen) {
            nextState = { filter: '' };
            nextProps.onResetData();
        }
        if (nextProps.isOpen && currentState.justOpened === undefined) {
            nextState = { ...currentState, justOpened: true };
        }
        if (currentState.justOpened && !nextProps.isOpen) {
            nextState = { ...currentState, justOpened: undefined };
        }

        return nextState;
    }

    public state = {
        filter: '',
        sourceSetModel: {},
    } as IState;

    public render() {
        const {
            close,
            isOpen,
            sourceSet,
            title,
            fetchDataHandler,
            fetching,
            classes,
        } = this.props;

        const { filter, sourceSetModel, justOpened } = this.state;

        return (
            <Dialog
                open={isOpen}
                TransitionComponent={TransitionComponent}
                keepMounted={true}
                onClose={close}
            >
                <DialogTitle title={title} close={close} />
                <DialogContent className={classes.wrapper}>
                    <PhraseFilter
                        placeholder={TranslationHelper.translate(
                            'Find by name'
                        )}
                        filter={filter}
                        omitEmpty={true}
                        onFilter={fetchDataHandler}
                        onReset={this.onResetData}
                        onChange={this.onFilterChange}
                        autoFocus={true}
                        shouldFocus={justOpened}
                    />
                    {fetching && (
                        <LinearProgress
                            variant="query"
                            className={classes.progress}
                        />
                    )}
                    {sourceSet && sourceSetModel ? (
                        <SourceSetGrid
                            sourceSet={sourceSet}
                            sourceSetModel={sourceSetModel}
                            selectedSourceSetElementId={null}
                            cellInteractionHandler={this.handleCellInteraction}
                        />
                    ) : (
                        <div className={classes.gridBorder} />
                    )}
                </DialogContent>
            </Dialog>
        );
    }

    private onResetData = () => {
        this.onFilterChange('');
        this.props.onResetData();
    };

    private onFilterChange = (filter: string) => {
        this.setState({ filter });
        if (!filter) {
            this.props.onResetData();
        }
    };

    private handleCellInteraction = (data: ISourceSetEntity) => {
        this.props.confirm(data);
        this.props.close();
    };
}

export default compose(Themable)(GridSearchDialog);
