import React from 'react';

import { Redirect, Route } from 'react-router-dom';

import { useMediaQuery } from '@material-ui/core';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import AppConfig from '../../../../constants/AppConfig';

import PlanningPage from '../../../../pages/planning/PlanningPage';
import AdminPage from '../../../../pages/admin';
import DashboardPage from '../../../../pages/dashboard/DashboardPage';
import DiscoveryPage from '../../../../pages/discovery/DiscoveryPage';
import IFramePage from '../../../../pages/iframe/IFramePage';
import LoginPageContainer from '../../../../pages/login/LoginPageContainer';
import RegistersPage from '../../../../pages/registers/RegistersPage';
import ReportsPage from '../../../../pages/reports/ReportsPage';
import ServiceCenterPage from '../../../../pages/serviceCenter/ServiceCenterPage';
import ManagementPage from '../../../../pages/management/ManagementPage';
import CustomerServicePage from '../../../../pages/customerService/CustomerServicePage';
import SettingsPage from '../../../../pages/settings';
import AlertsPage from '../../../../pages/alerts/AlertsPage';
import AuthGuard from '../../../AuthGuard';
import ConfigPreloadGuard from '../../../ConfigPreloadGuard';
import PageTemplate from '../../../PageTemplate';
import ErrorDialog from '../../../dialogs/ErrorDialog';
import NoInternetDialog from '../../../dialogs/NoInternetDialog';
import { AccessibleRoute } from '../../../accessControl/components';
import LinkVideoPlayer from '../../../LinkVideoPlayer/LinkVideoPlayer';
import { MOBILE } from '../../../../constants/dictionaries/ScreenSizeConst';
import { ILinkVideoArgs } from '../../../../services/discovery/_shared/urlMakers';
import { useToolkitDispatch } from '../../../../hooks';
import {
    setVideoParams,
    toggleVideoDialog,
} from '../../../../state/ui/videoUrl';
import { paramsToObject } from '../../../../helpers/paramsToObject';

const App = () => {
    const isMobile = useMediaQuery(MOBILE);
    const toolkitDispatch = useToolkitDispatch();

    const redirectHandler = () => {
        if (isMobile) {
            return <Redirect to={'/discovery'} />;
        }
        if (AppConfig.instance.getConfigKey(AppConfig.PROPERTY_DASHBOARD)) {
            return <Redirect to={'/dashboard'} />;
        }
        return <Redirect to={'/discovery'} />;
    };

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <AuthGuard renderCover={() => <LoginPageContainer />}>
                <ConfigPreloadGuard fetchOnlyTranslations={false}>
                    <PageTemplate>
                        <Route exact={true} path="/">
                            {redirectHandler()}
                        </Route>
                        <AccessibleRoute
                            check={(privileges) => privileges.discoveryContext}
                            path="/discovery"
                            component={DiscoveryPage}
                        />
                        {AppConfig.instance.getConfigKey(
                            AppConfig.PROPERTY_DASHBOARD
                        ) && (
                            <AccessibleRoute
                                check={(privileges) =>
                                    privileges.dashboardContext
                                }
                                path="/dashboard"
                                component={DashboardPage}
                            />
                        )}

                        {AppConfig.instance.getConfigKey(
                            AppConfig.PROPERTY_REGISTERS
                        ) && (
                            <AccessibleRoute
                                check={(privileges) =>
                                    privileges.registersContext
                                }
                                path="/registers"
                                component={RegistersPage}
                            />
                        )}

                        {AppConfig.instance.getConfigKey(
                            AppConfig.PROPERTY_ALERTS
                        ) && (
                            <AccessibleRoute
                                check={(privileges) => privileges.alertsContext}
                                path="/alerts"
                                component={AlertsPage}
                            />
                        )}

                        {AppConfig.instance.getConfigKey(
                            AppConfig.PROPERTY_REPORTS
                        ) && (
                            <AccessibleRoute
                                check={(privileges) =>
                                    privileges.reportsContext
                                }
                                path="/reports"
                                component={ReportsPage}
                            />
                        )}

                        <AccessibleRoute
                            check={(privileges) =>
                                privileges.manageGroups ||
                                privileges.eTollReadOnly
                            }
                            path="/management"
                            component={ManagementPage}
                        />
                        <AccessibleRoute
                            check={(privileges) =>
                                privileges.contextCustomerServiceCentre
                            }
                            path="/customer-service"
                            component={CustomerServicePage}
                        />
                        <AccessibleRoute
                            check={(privileges) => privileges.contextPlanning}
                            path="/planning"
                            component={PlanningPage}
                        />
                        <AccessibleRoute
                            check={(privileges) => privileges.adminPanel}
                            path="/admin"
                            component={AdminPage}
                        />

                        <Route path="/user-account" component={SettingsPage} />

                        <Route
                            path="/service-center"
                            component={ServiceCenterPage}
                        />

                        <Route path="/iframe/:iframe" component={IFramePage} />

                        <Route
                            path="/player"
                            render={() => {
                                const urlParams = new URLSearchParams(
                                    window.location.search
                                );
                                const entries = urlParams.entries();
                                const params = paramsToObject(
                                    entries
                                ) as ILinkVideoArgs;
                                if (params.monitoredId) {
                                    toolkitDispatch(setVideoParams(params));

                                    toolkitDispatch(toggleVideoDialog());
                                }
                                return redirectHandler();
                            }}
                        />
                        <LinkVideoPlayer />
                        <NoInternetDialog />
                        <ErrorDialog />
                    </PageTemplate>
                </ConfigPreloadGuard>
            </AuthGuard>
        </LocalizationProvider>
    );
};

export default App;
