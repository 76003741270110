import React, { Component, ComponentType, MouseEvent } from 'react';
import { compose } from 'redux';

import { AgGridReact } from 'ag-grid-react';

import {
    ColDef,
    ColGroupDef,
    GridApi,
    GridOptions,
    GridReadyEvent,
} from 'ag-grid-community';
import classNames from 'classnames';
import { FieldProps } from 'formik';

import { Button, FormHelperText } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import { Themable, TThemableProps } from './Themable.hoc';

import TranslationHelper from '../../../../helpers/TranslationHelper';
import {
    gridTranslateFunc,
    gridValueParsers,
} from '../../../SourceSetGrid/_utils';
import DeleteRowRenderer from './components/DeleteRowRenderer';

interface IOwnProps {
    gridProps: GridOptions;
    deleteRows?: boolean;
    addRows?: {
        [keys: string]: any;
    };
    rowDataTypes?: {
        [keys: string]: 'number';
    };
    additionalActionComponent?: React.DetailedReactHTMLElement<
        any,
        HTMLElement
    >;
    isAdditionalActionComponentDisabled?: boolean;
    error: boolean;
    helperText?: string;
    disabled?: boolean;
}

type TProps = IOwnProps & TThemableProps & FieldProps;

interface IState {
    uniqueDataKeysHash: string;
    columnDefs: Array<ColDef | ColGroupDef>;
    defaultColDef?: ColDef;
}

class EditableGridWrapper extends Component<TProps, IState> {
    public static getDerivedStateFromProps(
        nextProps: TProps,
        lastState: IState
    ) {
        const { gridProps, deleteRows, rowDataTypes, disabled } = nextProps;

        const uniqueDataKeys = Array.from(
            new Set(gridProps.rowData?.flatMap((obj) => Object.keys(obj)))
        );
        const uniqueDataKeysHash = uniqueDataKeys.join();

        if (uniqueDataKeysHash === lastState.uniqueDataKeysHash) {
            return null;
        }

        const columnDefs = uniqueDataKeys.map((dataKey) => ({
            field: dataKey,
            headerName: TranslationHelper.translate(dataKey),
            valueParser: rowDataTypes?.[dataKey]
                ? gridValueParsers[rowDataTypes[dataKey]]
                : undefined,
            sortable: true,
        }));

        if (!disabled && deleteRows) {
            const deleteRowColumnDef = {
                field: '',
                headerName: '',
                editable: false,
                cellRenderer: 'deletableRowRenderer',
                width: 50,
                valueParser: undefined,
                sortable: false,
            };
            columnDefs.push(deleteRowColumnDef);
        }

        const defaultColDef = {
            flex: 1,
            editable: !disabled,
            resizable: true,
        };

        return {
            uniqueDataKeysHash,
            columnDefs,
            defaultColDef,
        };
    }

    public state = {
        uniqueDataKeysHash: '',
        columnDefs: [],
        defaultColDef: undefined,
    };

    private gridApi: GridApi | null = null;
    private frameworkComponents = {
        deletableRowRenderer: DeleteRowRenderer,
    };

    public onGridReady = (params: GridReadyEvent) => {
        this.gridApi = params.api;
        this.gridApi.addEventListener('rowDeleted', this.updateForm);
        this.gridApi.addEventListener('cellEditingStopped', this.updateForm);

        this.gridApi.sizeColumnsToFit();
    };

    public addRow = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const { addRows } = this.props;
        if (this.gridApi) {
            this.gridApi.applyTransaction({ add: [{ ...addRows }] });
            this.updateForm();
        }
    };

    public getAllRows = () => {
        if (!this.gridApi) {
            return;
        }
        const data: any = [];
        this.gridApi.forEachNode((node) => data.push(node.data));
        return data;
    };

    public updateForm = () => {
        const { field, form } = this.props;
        form.setFieldValue(field.name, this.getAllRows());
        form.validateField(field.name);
    };

    public render() {
        const {
            classes,
            gridProps,
            addRows,
            additionalActionComponent,
            isAdditionalActionComponentDisabled,
            error,
            helperText,
            disabled,
        } = this.props;
        const gridClassNames = classNames({
            [classes.grid]: true,
            ['ag-theme-balham']: true,
            [classes.error]: error,
        });

        return (
            <div className={classes.container}>
                <div className={gridClassNames}>
                    <AgGridReact
                        rowData={gridProps.rowData}
                        columnDefs={this.state.columnDefs}
                        defaultColDef={this.state.defaultColDef}
                        onGridReady={this.onGridReady}
                        stopEditingWhenCellsLoseFocus={true}
                        frameworkComponents={this.frameworkComponents}
                        localeTextFunc={gridTranslateFunc}
                    />
                </div>
                <FormHelperText error={error}>{helperText}</FormHelperText>
                <div className={classes.actions}>
                    {additionalActionComponent &&
                        React.cloneElement(additionalActionComponent, {
                            disabled: isAdditionalActionComponentDisabled,
                        })}
                    {addRows && (
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<AddIcon />}
                            onClick={this.addRow}
                            disabled={disabled}
                        >
                            {TranslationHelper.translate('Add row')}
                        </Button>
                    )}
                </div>
            </div>
        );
    }
}

export default compose(Themable)(
    EditableGridWrapper
) as ComponentType<IOwnProps>;
