import React, { useEffect, useState } from 'react';

import { SelectionChangedEvent } from 'ag-grid-community';

import { Divider, LinearProgress, Paper } from '@material-ui/core';

import { Box } from '@mui/material';

import {
    useRoutesSourceSetModel,
    useSelectedCategory,
    useSelectedDates,
    useSelectedRoutes,
} from '../../../../state/ui/planning/routes/index.hooks';
import { ISourceSetEntity } from '../../../../state/types';
import {
    useTasks,
    useTasksStatus,
} from '../../../../state/ui/planning/tasks/index.hooks';
import {
    fetchPlanningTasks,
    resetTasks,
} from '../../../../state/ui/planning/tasks';

import { useToolkitDispatch } from '../../../../hooks';
import TranslationHelper from '../../../../helpers/TranslationHelper';
import useAsyncThunkWithSnackbar from '../../../../hooks/useAsyncThunkWithSnackbar';

import { useStyles } from '../../Themable.hooks';

import PaneHeader from '../../../../components/PaneHeader';
import SearchField from '../../../../components/common/SearchField/SearchField';
import SourceSetGrid from '../../../../components/SourceSetGrid';

const CHECKBOX_POSITION = 0;

interface IOwnProps {
    selectedItems: string[];
    handleFeatureSelectedOnGrid: (items: string[]) => void;
    handleSelectFeatures: any;
}

const PlanningTasksGrid = ({
    selectedItems,
    handleFeatureSelectedOnGrid,
}: IOwnProps) => {
    const sourceSetModel = useRoutesSourceSetModel();

    const [filter, setFilter] = useState(sourceSetModel?.quickFilter || '');
    const classes = useStyles();
    const tasks = useTasks();
    const status = useTasksStatus();
    const selectedRoutes = useSelectedRoutes();
    const activityCategoryId = useSelectedCategory();
    const dates = useSelectedDates();
    const toolkitDispatch = useToolkitDispatch();
    const handleAsyncRequest = useAsyncThunkWithSnackbar();

    useEffect(() => {
        if (selectedRoutes.length && activityCategoryId) {
            handleAsyncRequest({
                asyncAction: fetchPlanningTasks({
                    selectedRoutes,
                    activityCategoryId,
                    dates,
                }),
            });
        } else {
            toolkitDispatch(resetTasks());
        }
    }, [selectedRoutes, dates]);
    const handleCellInteraction = (data: ISourceSetEntity) => {};

    const onSelectionChanged = (e: SelectionChangedEvent) => {
        const selectedNodes = e.api
            .getSelectedNodes()
            .filter(Boolean)
            .map((node) => {
                return node.data.id;
            });

        if (!selectedNodes.length) {
            handleFeatureSelectedOnGrid([]);
            return;
        }
        if (selectedNodes.length) {
            handleFeatureSelectedOnGrid(selectedNodes);
        }
    };
    const multiSelectProps = {
        rowSelection: 'multiple',
        checkBox: { index: CHECKBOX_POSITION },
        onSelectionChanged,
        items: selectedItems.map((item) => {
            return { sourceSetId: item };
        }),
    };
    return (
        <Paper className={classes.gridPane}>
            <div className={classes.wrapper}>
                <PaneHeader
                    title={TranslationHelper.translate('Tasks')}
                    renderLeftCustomControls={() => (
                        <>
                            <SearchField
                                value={filter}
                                filterChange={setFilter}
                            />
                        </>
                    )}
                />

                {!selectedRoutes.length && (
                    <Box
                        sx={{
                            p: 1,
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {TranslationHelper.translate(
                            'Select route to load tasks'
                        )}
                    </Box>
                )}
                {status === 'error' && selectedRoutes.length && (
                    <Box
                        sx={{
                            p: 1,
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {TranslationHelper.translate(
                            'Cannot load tasks for selected route, select different route'
                        )}
                    </Box>
                )}
                {status === 'loading' ? <LinearProgress /> : <Divider />}
                {tasks && (
                    <SourceSetGrid
                        sourceSet={tasks}
                        cellInteractionHandler={handleCellInteraction}
                        quickFilterValue={filter}
                        sourceSetModel={sourceSetModel}
                        selectedSourceSetElementId={
                            selectedItems[selectedItems.length - 1]
                        }
                        multiSelectProps={multiSelectProps}
                    />
                )}
            </div>
        </Paper>
    );
};

export default PlanningTasksGrid;
