import React, { Component, ComponentType } from 'react';
import { compose } from 'redux';

import { debounce } from 'lodash';

import { AgGridReact } from 'ag-grid-react';

import {
    CellFocusedEvent,
    CellPosition,
    ColDef,
    ColumnApi,
    GridApi,
    GridOptions,
    ICellRendererParams,
    NavigateToNextCellParams,
    RowNode,
} from 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import { Connectable, TConnectableProps } from './Connectable.hoc';
import { Themable, TThemableProps } from './Themable.hoc';

import { ISourceSetEntity } from '../../state/types';

import { convertArrayToMap } from '../../helpers/converters';
import { sortAlphabetically } from '../../helpers/comparators';
import { IOwnProps, IState } from './types';

import AppConfig from '../../constants/AppConfig';
import { getColumnDefs } from '../../pages/discovery/components/_utils/columnDefsGetter';

import defaultColDef from './_utils/defaultColDef';
import translateFunc from './_utils/gridTranslateFunc';
import AgGridEnterprise from './components/AgGridEnterprise';
import OptionsCellRenderer from './components/cellRenderers/OptionsCellRenderer';
import ListWithHoverCellRenderer from './components/cellRenderers/ListWithHoverCellRenderer';
import ActionButtons from './components/cellRenderers/ActionsButtons';

const frameworkComponents = {
    optionsCellRenderer: OptionsCellRenderer,
    actionsCellRenderer: ActionButtons,
    groupsCellRenderer: (params: ICellRendererParams) => {
        return (
            <ListWithHoverCellRenderer
                list={
                    params.value?.sort((a: string, b: string) =>
                        sortAlphabetically(a, b)
                    ) || []
                }
            />
        );
    },
};

type TProps = IOwnProps & TThemableProps & TConnectableProps;

class SourceSetGrid extends Component<TProps, IState> {
    public static getDerivedStateFromProps(
        nextProps: TProps,
        lastState: IState
    ) {
        const {
            sourceSet,
            selectedSourceSetElementId,
            customData,
            userSettings,
            layersAttributes,
            hide,
            actionHandlers,
        } = nextProps;

        const { checkBox, checkBoxHeader } = nextProps.multiSelectProps || {};
        const attributes = [
            ...sourceSet.attributes,
            ...(sourceSet._meta.geolocated ? layersAttributes : []),
        ];

        const enterpriseKey = AppConfig?.instance.getConfigKey(
            AppConfig.PROPERTY_GRID
        )?.key;

        const newColumnDefsHash = attributes
            .map(({ id }) => id)
            .sort()
            .toString();
        const columnDefs: ColDef[] =
            newColumnDefsHash === lastState.columnDefsHash
                ? lastState.columnDefs
                : getColumnDefs(
                      attributes,
                      customData,
                      userSettings,
                      sourceSet,
                      !!enterpriseKey,
                      checkBox,
                      checkBoxHeader,
                      hide,
                      actionHandlers
                  );

        if (columnDefs !== lastState.columnDefs) {
            return {
                columnDefsHash: newColumnDefsHash,
                columnDefs,
                lastSelectedElementId: lastState.isGridReady
                    ? selectedSourceSetElementId
                    : lastState.lastSelectedElementId,
                shouldUpdateScroll:
                    selectedSourceSetElementId !==
                    lastState.lastSelectedElementId,
            };
        } else {
            return null;
        }
    }

    public state: IState = {
        columnDefsHash: '',
        columnDefs: [],
        lastSelectedElementId: undefined,
        shouldUpdateScroll: false,
        isGridReady: false,
    };

    private componentIsMounted: boolean = false;
    private gridApi: GridApi | null = null;
    private gridColumnApi: ColumnApi | null = null;
    private delayedSetQuickFilter = debounce((value) => {
        if (!this.gridApi) {
            return;
        }
        this.gridApi.setQuickFilter(value);
        this.afterChange();
    }, 500);

    public componentDidMount() {
        this.componentIsMounted = true;

        if (this.gridApi && this.props.quickFilterValue !== undefined) {
            this.gridApi.setQuickFilter(this.props.quickFilterValue);
        }
    }

    public componentWillUnmount() {
        this.componentIsMounted = false;
        this.delayedSetQuickFilter.cancel();
    }

    public componentDidUpdate(prevProps: TProps, prevState: IState) {
        if (!this.gridApi) {
            return;
        }
        if (this.state.isGridReady === false) {
            this.setState({ isGridReady: true });
        }
        if (
            this.props.quickFilterValue !== undefined &&
            this.props.quickFilterValue !== prevProps.quickFilterValue
        ) {
            this.delayedSetQuickFilter(this.props.quickFilterValue);
        }
        if (prevProps.sourceSet.entities !== this.props.sourceSet.entities) {
            this.updateGrid(
                prevProps.sourceSet.entities,
                this.props.sourceSet.entities
            );
        }
        if (
            prevProps.selectedSourceSetElementId !==
                this.props.selectedSourceSetElementId ||
            this.props.multiSelectProps?.items?.length !==
                prevProps.multiSelectProps?.items?.length
        ) {
            this.props.multiSelectProps?.checkBox
                ? this.ensureVisibleMultiple()
                : this.ensureVisible();
        }

        if (prevProps.sourceSet.id !== this.props.sourceSet.id) {
            this.delayedSetQuickFilter.cancel();
        }
    }

    public updateGrid = (
        prevEntities: ISourceSetEntity[],
        nextEntities: ISourceSetEntity[]
    ) => {
        if (!this.gridApi) {
            return;
        }
        // ! temp (?) fix for #50671 - disabling persistent cell selection, as it was causing wrong object to be selected after data update if grid sorted by date
        // ! test on webx.xtrack.com
        this.gridApi.clearFocusedCell();

        const mappedPrevEntities = convertArrayToMap(prevEntities, 'id');
        const mappedNextEntities = convertArrayToMap(nextEntities, 'id');
        const toUpdate = [];
        const toAdd = [];
        const toRemove = [];

        for (const [key, value] of mappedPrevEntities) {
            if (mappedNextEntities.has(key)) {
                toUpdate.push(mappedNextEntities.get(key));
                mappedNextEntities.delete(key);
            } else {
                toRemove.push(value);
            }
        }

        for (const value of mappedNextEntities.values()) {
            toAdd.push(value);
        }

        this.gridApi.applyTransactionAsync(
            {
                update: toUpdate,
                add: toAdd,
                remove: toRemove,
            },
            this.afterChange
        );
    };

    public ensureVisible = () => {
        if (this.gridApi) {
            this.gridApi.forEachNodeAfterFilterAndSort((node) => {
                if (
                    node.data &&
                    node.data.id === this.props.selectedSourceSetElementId
                ) {
                    node.setSelected(true, true);

                    if (this.gridApi && this.state.shouldUpdateScroll) {
                        this.gridApi.ensureNodeVisible(node);
                    }
                } else {
                    node.setSelected(false);
                }
            });
        }
    };

    public ensureVisibleMultiple = () => {
        if (!this.gridApi) return;

        const itemIdsSet = new Set(
            this.props.multiSelectProps?.items?.map((item) => item.sourceSetId)
        );
        const shouldUpdateScroll = this.state.shouldUpdateScroll;
        const selectedSourceSetElementId =
            this.props.selectedSourceSetElementId;
        let nodeToScrollTo: RowNode | null = null;
        this.gridApi.deselectAll();
        this.gridApi.forEachNodeAfterFilterAndSort((node) => {
            if (itemIdsSet.has(node.data.id)) {
                node.setSelected(true, false, true);
                if (
                    shouldUpdateScroll &&
                    selectedSourceSetElementId === node.data.id
                ) {
                    nodeToScrollTo = node;
                }
            }
        });
        if (nodeToScrollTo) {
            requestAnimationFrame(() => {
                this.gridApi?.ensureNodeVisible(nodeToScrollTo);
            });
        }
    };

    public processCellInteraction = (data?: ISourceSetEntity) => {
        if (
            !data ||
            this.props.isGridFullscreen ||
            !this.props.cellInteractionHandler
        ) {
            return;
        }
        this.props.cellInteractionHandler(data);
    };

    public afterChange = () => {
        const uids: Set<string> = new Set();
        if (this.gridApi && this.gridColumnApi && this.componentIsMounted) {
            this.gridApi.forEachNodeAfterFilterAndSort((node, index) => {
                uids.add(node.data?.id);
            });
            this.props.storeSourceSetModel(this.props.sourceSet.id, {
                filterModel: this.gridApi.getFilterModel(),
                sortModel: this.gridColumnApi.getColumnState(),
                sourceSetElementIds: uids,
                quickFilter: this.props.quickFilterValue || '',
            });
        }
    };
    public setGridState = () => {
        this.setFilterAndSortModels();
    };

    public setFilterAndSortModels = () => {
        const { sourceSetModel, quickFilterValue } = this.props;
        if (this.gridApi && this.gridColumnApi && sourceSetModel) {
            this.gridApi.setFilterModel(sourceSetModel.filterModel);
            this.gridColumnApi.applyColumnState({
                state: sourceSetModel.sortModel,
            });
            this.gridApi.setQuickFilter(quickFilterValue);
        }
    };

    public handleGridReady = (params: {
        api: GridApi;
        columnApi: ColumnApi;
    }) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.setRowData(this.props.sourceSet.entities);
        this.gridColumnApi.autoSizeAllColumns();
        this.setGridState();

        this.gridApi.addEventListener('filterModified', this.afterChange);
        this.gridApi.addEventListener('columnVisible', this.afterChange);
        this.gridApi.addEventListener('columnMoved', this.afterChange);
        this.gridApi.addEventListener(
            'columnRowGroupChanged',
            this.afterChange
        );

        this.gridApi.addEventListener('filterChanged', this.afterChange);
        this.gridApi.addEventListener('sortChanged', this.afterChange);
        this.gridApi.addEventListener('cellFocused', this.handleCellFocus);
        this.props.multiSelectProps?.checkBox
            ? this.ensureVisibleMultiple()
            : this.ensureVisible();
        if (this.props.setGridApi) {
            this.props.setGridApi(this.gridApi, this.gridColumnApi);
        }
        this.delayedSetQuickFilter(this.props.quickFilterValue);
    };

    public handleCellFocus = (event: CellFocusedEvent) => {
        if (!this.gridApi || !this.componentIsMounted) {
            return;
        }
        if (event.rowIndex !== null) {
            const row = this.gridApi.getDisplayedRowAtIndex(event.rowIndex);
            if (row) {
                this.processCellInteraction(row.data);
            }
        }
    };

    public navigateToNextCellHandler = (
        params: NavigateToNextCellParams
    ): CellPosition => {
        const previousCell = params.previousCellPosition;
        const suggestedNextCell = params.nextCellPosition;
        if (suggestedNextCell !== null) {
            if (!this.gridApi || !this.componentIsMounted) {
                return suggestedNextCell;
            }

            const KEY_UP = 38;
            const KEY_DOWN = 40;
            const KEY_LEFT = 37;
            const KEY_RIGHT = 39;

            switch (params.key) {
                case KEY_DOWN:
                    this.gridApi.forEachNodeAfterFilterAndSort((node) => {
                        if (previousCell.rowIndex + 1 === node.rowIndex) {
                            node.setSelected(true);
                        }
                    });
                    return suggestedNextCell;
                case KEY_UP:
                    this.gridApi.forEachNodeAfterFilterAndSort((node) => {
                        if (previousCell.rowIndex - 1 === node.rowIndex) {
                            node.setSelected(true);
                        }
                    });
                    return suggestedNextCell;
                case KEY_LEFT:
                case KEY_RIGHT:
                default:
                    return suggestedNextCell;
            }
        } else {
            return params.previousCellPosition;
        }
    };

    public getRowNodeIdHandler = (data: ISourceSetEntity) => data.id;

    public render() {
        const { classes, onRowDataChanged } = this.props;
        const {
            nextCellHandler,
            onSelectionChanged,
            rowSelection,
            disabledClickSelectionRenderers,
        } = this.props.multiSelectProps || {};
        const enterpriseKey =
            AppConfig &&
            AppConfig.instance.getConfigKey(AppConfig.PROPERTY_GRID)?.key;
        const gridProps: GridOptions = {
            onRowDataChanged,
            columnDefs: this.state.columnDefs,
            defaultColDef,
            rowSelection: rowSelection ? rowSelection : 'single',
            onGridReady: this.handleGridReady,
            embedFullWidthRows: true,
            frameworkComponents,
            suppressScrollOnNewData: true,
            localeTextFunc: translateFunc,
            getRowNodeId: this.getRowNodeIdHandler,
            navigateToNextCell:
                nextCellHandler ?? this.navigateToNextCellHandler,
            onSelectionChanged,
            onCellFocused: (e) => {
                const { api, column } = e;

                if (column) {
                    const cellRenderer = column.getColDef()
                        .cellRenderer as string;

                    if (
                        disabledClickSelectionRenderers?.includes(cellRenderer)
                    ) {
                        api.setSuppressRowClickSelection(true);
                    } else {
                        api.setSuppressRowClickSelection(false);
                    }
                }
            },
            suppressContextMenu: true,
            cacheQuickFilter: true,
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: 'agTotalAndFilteredRowCountComponent',
                        align: 'left',
                    },
                ],
            },
            excelStyles: [
                {
                    id: 'booleanType',
                    dataType: 'Boolean',
                },
            ],
        };

        const enterpriseProps = {
            ...gridProps,
            enterpriseKey,
        };

        return (
            <div className={classes.container}>
                <div className={`ag-theme-balham ${classes.wrapper}`}>
                    {enterpriseKey ? (
                        <AgGridEnterprise {...enterpriseProps} />
                    ) : (
                        <AgGridReact {...gridProps} />
                    )}
                </div>
            </div>
        );
    }
}

export default compose(
    Themable,
    Connectable
)(SourceSetGrid) as ComponentType<IOwnProps>;
