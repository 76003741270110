import {
    createSlice,
    createSelector,
    createAsyncThunk,
} from '@reduxjs/toolkit';

import moment from 'moment';

import { ITaskPreFetchParams } from '../../../../../models/customerService';

import { TRootState } from '../../../../../store';

import { fetchCustomSourceSetRaw } from '../../../../../services/discovery/fetchCustomSourceSet';
import {
    IAttribute,
    ISourceSet,
    ISourceSetEntity,
    TStatus,
} from '../../../../../state/types';

import TimeFormatter from '../../../../../helpers/TimeFormatter';
import { removeDuplicatesById } from '../../../../../helpers/removeDuplicatesById';

import { TLocationWithCoordinates } from '../../clientsAndLocations';

interface IState {
    selectedEventId: number | null;
    events: ISourceSet[] | null;
    status: TStatus;
    dates: {
        from: string;
        to: string;
    };
    openSectionEvent: boolean;
}

export const initialState: IState = {
    events: null,
    selectedEventId: null,
    status: 'idle',
    openSectionEvent: false,
    dates: {
        from: moment(new Date()).format('YYYY-MM-DD'),
        to: moment(new Date()).format('YYYY-MM-DD'),
    },
};
const getRadius = (radius: number) => {
    if (radius < 50) {
        return 50;
    }
    if (radius > 200) {
        return 200;
    }
    return radius;
};
export const fetchEvents = createAsyncThunk(
    'get:ui/customerService/bottomGrid/events',
    async (params: ITaskPreFetchParams) => {
        const locations = params.selectedClientsAndLocations.filter(
            (item) => item.id
        ) as TLocationWithCoordinates[];
        const responses = locations.length
            ? await Promise.all(
                  locations.map(async (location) => {
                      return await fetchCustomSourceSetRaw({
                          api: `/rest/api/source-sets/archive-events/near`,
                          method: 'GET',
                          label: '',
                          params: {
                              from: TimeFormatter.toISOString(
                                  moment(params.from).startOf('day') ||
                                      moment(new Date()).format('YYYY-MM-DD')
                              ),
                              to: TimeFormatter.toISOString(
                                  moment(params.to).endOf('day') ||
                                      moment(new Date()).format('YYYY-MM-DD')
                              ),
                              radius: getRadius(location.radius || 100),
                              latitude: location.coordinates.y,
                              longitude: location.coordinates.x,
                              monitoredId:
                                  location.coordinates.y.toString() +
                                  location.coordinates.x.toString(),
                              reportId: 'archiveEvents',
                          },
                      });
                  })
              )
            : null;

        return responses;
    }
);

const events = createSlice({
    name: 'events',
    initialState,
    reducers: {
        selectEvent(state, action) {
            state.selectedEventId = action.payload;
        },

        toggleCollapseTasks(state) {
            state.openSectionEvent = !state.openSectionEvent;
        },
        setDates(state, action) {
            state.dates = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchEvents.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(fetchEvents.fulfilled, (state, action) => {
            state.events = action.payload;
            state.status = 'done';
        });
    },
});

export const getEvents = (state: TRootState) =>
    state.ui.customerService.events.events;

export const getSelectedEventId = (state: TRootState) =>
    state.ui.customerService.events.selectedEventId;

export const getEventsStatus = (state: TRootState) =>
    state.ui.customerService.events.status;

export const getSourceSetModels = (state: TRootState) =>
    state.ui.discovery.general.sourceSetModels;

export const getEventDates = (state: TRootState) =>
    state.ui.customerService.events.dates;

export const getOpenSectionEvents = (state: TRootState) =>
    state.ui.customerService.events.openSectionEvent;

export const getEventsSourceSetModel = createSelector(
    [getSourceSetModels],
    (models) => models.events || null
);
function getLongestAttributesList(arr: ISourceSet[]): IAttribute[] {
    if (arr.length === 0) {
        return [];
    }
    const sourceSetWithLongestAttributes = arr.reduce(
        (maxObj, obj) =>
            obj.attributes.length > maxObj.attributes.length ? obj : maxObj,
        arr[0]
    );

    return sourceSetWithLongestAttributes.attributes;
}

export const getEventsAsSourceSet = createSelector(
    [getEvents],
    (events): ISourceSet | null =>
        events && {
            id: events[0].id,
            definitionId: events[0].definitionId,
            label: events[0].label,
            attributes: getLongestAttributesList(events),
            layersAttributes: [],
            _meta: { ...events[0]._meta, geolocated: false },
            entities: removeDuplicatesById(
                events
                    .map((event: ISourceSet): any => {
                        return event.entities.map(
                            (eventValue: ISourceSetEntity): any => {
                                return {
                                    ...eventValue,

                                    _meta: {},
                                };
                            }
                        );
                    })
                    .flat()
            ),
        }
);

export const { setDates, selectEvent, toggleCollapseTasks } = events.actions;

export default events.reducer;
