import {
    makeGetRequestSettings,
    makeRestReadCollection,
} from '../../common/utils';
import apiClient from '../../common/apiClient';
import { makeCustomerServiceUrl } from '..';
import {
    IContractItem,
    IServiceContractsUrlParams,
} from '../../../models/customerService';

export const restGetContractItems = (
    params: IServiceContractsUrlParams
): Promise<IContractItem[]> =>
    apiClient.send(
        makeGetRequestSettings(
            makeCustomerServiceUrl('/contract-items', params)
        )
    );

export const restGetContractItem = (
    id: number,
    cycleId: number | undefined
): Promise<IContractItem> =>
    apiClient.send(
        makeGetRequestSettings(
            makeCustomerServiceUrl(`/contract-items/${id}`, { cycleId })
        )
    );

export const restReadContractItemsCollection =
    makeRestReadCollection(restGetContractItems);
