import { useSelector } from 'react-redux';
import {
    getTasksPaneMode,
    getTasksAsSourceSet,
    getTasksSourceSetModel,
    getSelectedTask,
    getSelectedTaskId,
    getSelectedTasks,
    isTasksPaneVisible,
    getTasksStatus,
} from './';

export const useIsTasksPaneVisible = () => useSelector(isTasksPaneVisible);
export const useTasks = () => useSelector(getTasksAsSourceSet);
export const useTasksMode = () => useSelector(getTasksPaneMode);
export const useTasksSourceSetModel = () => useSelector(getTasksSourceSetModel);
export const useTasksStatus = () => useSelector(getTasksStatus);
export const useSelectedTask = () => useSelector(getSelectedTask);
export const useSelectedTaskId = () => useSelector(getSelectedTaskId);

export const useSelectedTasks = () => useSelector(getSelectedTasks);
