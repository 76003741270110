import {
    GridOptions,
    ServerSideStoreType,
    ValueGetterParams,
} from 'ag-grid-community';

import { reportsDetailCellRenderer } from '../../../../../utils/reportDetailCellRenderer';
import TranslationHelper from '../../../../../../../helpers/TranslationHelper';

import ActionsButtons from '../components/cellRenderers/ActionsButtons';
import StatusIcon from '../components/cellRenderers/StatusIcon';
import { gridValueGetter } from '../../../../../../../components/SourceSetGrid/_utils';
import { TUserState } from '../../../../../../../state/user';

const frameworkComponents = {
    reportsDetailCellRenderer,
    actions: ActionsButtons,
    statusIcon: StatusIcon,
};

export const gridProps: GridOptions = {
    frameworkComponents,
    cacheBlockSize: 25,
    pagination: true,
    maxConcurrentDatasourceRequests: 2,
    paginationPageSize: 25,
    rowSelection: 'single',
};

export const serverSideGridProps = {
    ...gridProps,
    //aggrid type bug to be fixed in future versions - using enum instead of string result in broker partial data download feature
    serverSideStoreType: 'partial' as ServerSideStoreType,
};

export const getColDefs = (userSettings: TUserState) => {
    return [
        {
            field: 'status',
            headerName: TranslationHelper.translate('Status'),
            flex: 0.5,
            cellRenderer: 'statusIcon',
        },
        {
            field: 'id',
            headerName: TranslationHelper.translate('ID'),
            flex: 0.3,
        },
        {
            field: 'type.name',
            headerName: TranslationHelper.translate('Type'),
        },
        {
            headerName: TranslationHelper.translate('Objects'),
            resizable: true,
            cellRenderer: 'reportsDetailCellRenderer',
        },
        {
            field: 'from',
            flex: 2,
            headerName: TranslationHelper.translate('From'),
            valueGetter: (params: ValueGetterParams) =>
                gridValueGetter({
                    value: params.data?.from,
                    type: 'date',
                    userSettings: userSettings,
                }),
        },
        {
            field: 'to',
            headerName: TranslationHelper.translate('To'),
            valueGetter: (params: ValueGetterParams) =>
                gridValueGetter({
                    value: params.data?.to,
                    type: 'date',
                    userSettings: userSettings,
                }),
        },
        {
            field: 'generatedAt',
            headerName: TranslationHelper.translate('Generated at'),
            valueGetter: (params: ValueGetterParams) =>
                gridValueGetter({
                    value: params.data?.generatedAt,
                    type: 'date',
                    userSettings: userSettings,
                }),
        },
        {
            field: 'fileSize',
            headerName: TranslationHelper.translate('Size'),
            flex: 0.5,
        },

        {
            field: 'id',
            headerName: TranslationHelper.translate('Actions'),
            cellRenderer: 'actions',
        },
    ];
};
